


















import useConfirmForm from '@/composables/use-confirm-form'
import useI18n from '@/composables/use-i18n'
import useOrder from '@/composables/use-order'
import { InvoicePersonTo, InvoiceType } from '@/enums/Invoice'
import { computed, defineComponent, ref } from '@nuxtjs/composition-api'
import alert from '@/modules/alert'

export default defineComponent({
  props: {
    orderNum: {
      type: String,
      default: '',
    },
  },
  setup (props, { emit }) {
    const i18n = useI18n()
    const {
      invoice,
    } = useConfirmForm()

    const { changeInvoice } = useOrder()

    const invoiceValue = computed({
      get: () => invoice.value,
      set: (value) => { invoice.value = value },
    })

    const invoiceTitle = ref<Element | null>(null)

    const validateInvoice = () => {
      invoice.resetField()
      if (!invoice.value) {
        invoice.setError(String(i18n.t('field.invoice.message.required')))
        return false
      }
      if (invoice.value.to === InvoicePersonTo.MOBILE_CODE && !invoice.value.code) {
        invoice.setError(String(i18n.t('field.invoice.message.required')))
        alert(invoice.errorMessage)
        return false
      }
      if (invoice.value.to === InvoicePersonTo.NATURE && !invoice.value.code) {
        invoice.setError(String(i18n.t('field.invoice.message.required')))
        alert(invoice.errorMessage)
        return false
      }
      if (invoice.value.to === InvoicePersonTo.DONATE && !invoice.value.code) {
        invoice.setError(String(i18n.t('field.invoice.message.required')))
        alert(invoice.errorMessage)
        return false
      }
      if (invoice.value.type === InvoiceType.COMPANY && (!invoice.value.code || !invoice.value.name)) {
        invoice.setError(String(i18n.t('field.invoice.message.required')))
        alert(invoice.errorMessage)
        return false
      }
      return true
    }

    const confirm = async () => {
      if (!validateInvoice() || !invoice.value) return null
      await changeInvoice(props.orderNum, invoice.value)
      emit('close-modal')
    }

    return {
      invoiceTitle,

      invoice,
      invoiceValue,

      validateInvoice,
      confirm,
    }
  },
})
