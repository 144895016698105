




















































































import { defineComponent, onMounted, reactive, ref, toRefs, useContext } from '@nuxtjs/composition-api'

import GField from '@/components/GField.vue'
import GFieldBirthday from '@/components/GFieldBirthday.vue'

import useConfirmForm from '@/composables/use-confirm-form'
import useConfirmUserInfo from '@/composables/use-confirm-user-info'
import useI18n from '@/composables/use-i18n'

import LoginStep from '@/enums/LoginStep'
import ThirdParty from '@/enums/ThirdParty'
import alert from '@/modules/alert'
import dataLayer from '@/modules/data-layer'
import ggoApi from '@/modules/ggo-api'
import ggoLogger from '@/modules/ggo-logger'
import { LogName, LogToastMsg } from '@/interfaces/Logger'
import { RegisterDto } from '@/types/ggo-api/dto'

export default defineComponent({
  props: {
    email: {
      type: String || null,
      default: null,
    },
  },
  setup (props, { emit }) {
    const { query } = useContext()
    const i18n = useI18n()

    const { user, fetchUser } = useConfirmUserInfo()

    const {
      fields,
    } = useConfirmForm()

    const signup = async () => {
      if (!validate()) return
      /** 註冊 */
      let params: RegisterDto = {
        firstName: fields.firstName.value,
        lastName: fields.lastName.value,
        email: fields.email.value,
        password: fields.password.value,
        isSendNews: fields.isSendNews.value,
        birthday: fields.birthday.value,
        locale: i18n.locale,
      }
      const invitedId = fields.invitedId.value
      if (invitedId) {
        params = {
          ...params,
          invitedId,
        }
      }

      const { success } = await ggoApi.register(params)

      if (!success) {
        ggoLogger.info(LogName.TOAST, {
          type: 'danger',
          message: LogToastMsg.REGISTER_ERROR,
        })

        alert(i18n.t('user.registration.failed'))
      } else {
        await dataLayer.signUp('general')

        // eslint-disable-next-line no-undef
        fbq('track', 'CompleteRegistration')

        ggoLogger.info(LogName.TOAST, {
          type: 'success',
          message: LogToastMsg.REGISTER_SUCCESS,
        })

        /** 註冊完成移除存放的 invitedId */
        localStorage.removeItem('invitedId')
        /** 註冊成功後自動登入 */
        const params = {
          email: fields.email.value,
          password: fields.password.value,
        }

        const { success, data, error } = await ggoApi.login(params)

        if (success && data) {
          ggoLogger.info(LogName.TOAST, {
            type: 'success',
            message: LogToastMsg.LOGIN_REGISTER_SUCCESS,
          })

          localStorage.setItem('token', data.token)

          await ggoApi.updateUserInfo({ locale: i18n.locale })
          await fetchUser()

          if (user.value?.id) {
            await dataLayer.login('general')
            dataLayer.sendUserId(user.value.id)
          }
        } else {
          ggoLogger.info(LogName.TOAST, {
            type: 'danger',
            message: error ?? LogToastMsg.LOGIN_REGISTER_ERROR,
          })
        }

        emit('on-signed-up')
      }
    }

    onMounted(() => {
      if (query.value.id) {
        localStorage.setItem('invitedId', query.value.id as string)
        fields.invitedId.value = query.value.id as string
      }
      /** 抓到 id 後清空 params 以防止使用者更改推薦人 id */

      // 自動帶入 email
      if (props.email) {
        fields.email.value = props.email
        setDisabled(true)
      }
    })

    /** 判斷是否從 LoginOrSignup 來的
     *  若為 true，代表此 email 已被驗證無人註冊過
     *  設置 email 欄位為 disabled
    */
    const isDisabled = ref<Boolean>(false)
    const setDisabled = (disabled:Boolean) => {
      isDisabled.value = disabled
    }

    const fieldTemplateRefs = reactive({
      firstName: null as InstanceType<typeof GField> | null,
      lastName: null as InstanceType<typeof GField> | null,
      email: null as InstanceType<typeof GField> | null,
      password: null as InstanceType<typeof GField> | null,
      birthday: null as InstanceType<typeof GFieldBirthday> | null,
    })

    const invalidElements = ref<Element[]>([])

    /** 所有不合法的元素隊列，待稍後滾動至該元素處用 */
    const pushInvalidElement = (el: Element | undefined | null) => {
      if (el) invalidElements.value.push(el)
    }

    const validate = () => {
      // 將上一次的錯誤清空
      invalidElements.value = []

      fields.lastName.resetField()
      if (!fields.lastName.validate()) {
        pushInvalidElement(fieldTemplateRefs.lastName?.$el)
      }

      fields.firstName.resetField()
      if (!fields.firstName.validate()) {
        pushInvalidElement(fieldTemplateRefs.firstName?.$el)
      }

      fields.email.resetField()
      if (!fields.email.validate()) {
        pushInvalidElement(fieldTemplateRefs.email?.$el)
      }

      fields.password.resetField()
      if (!fields.password.validate()) {
        pushInvalidElement(fieldTemplateRefs.password?.$el)
      }

      fields.birthday.resetField()
      if (!fields.birthday.validate()) {
        pushInvalidElement(fieldTemplateRefs.birthday?.$el)
      }

      const isValid = !invalidElements.value.length

      return isValid
    }

    return {
      ThirdParty,

      LoginStep,

      isDisabled,

      fields,

      setDisabled,
      signup,

      firstNameField: toRefs(fieldTemplateRefs).firstName,
      lastNameField: toRefs(fieldTemplateRefs).lastName,
      emailField: toRefs(fieldTemplateRefs).email,
      passwordField: toRefs(fieldTemplateRefs).password,
      birthdayField: toRefs(fieldTemplateRefs).birthday,
    }
  },
})
