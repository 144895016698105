import { render, staticRenderFns } from "./AccountLoginOrSignup.vue?vue&type=template&id=e10f9e7e&scoped=true&lang=pug&"
import script from "./AccountLoginOrSignup.vue?vue&type=script&lang=ts&"
export * from "./AccountLoginOrSignup.vue?vue&type=script&lang=ts&"
import style0 from "./AccountLoginOrSignup.vue?vue&type=style&index=0&id=e10f9e7e&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e10f9e7e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AccountCheckEmail: require('/home/runner/work/gg_code/gg_code/nuxt/components/account/status/AccountCheckEmail.vue').default,AccountLogin: require('/home/runner/work/gg_code/gg_code/nuxt/components/account/status/AccountLogin.vue').default,AccountSignup: require('/home/runner/work/gg_code/gg_code/nuxt/components/account/status/AccountSignup.vue').default,GCard: require('/home/runner/work/gg_code/gg_code/nuxt/components/GCard.vue').default})
