import { render, staticRenderFns } from "./TheNavbar.vue?vue&type=template&id=51d6d026&lang=pug&"
import script from "./TheNavbar.vue?vue&type=script&lang=ts&"
export * from "./TheNavbar.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NuxtPicture: require('/home/runner/work/gg_code/gg_code/nuxt/components/NuxtPicture.vue').default,LanguageSwitcher: require('/home/runner/work/gg_code/gg_code/nuxt/components/LanguageSwitcher.vue').default,GBtn: require('/home/runner/work/gg_code/gg_code/nuxt/components/GBtn.vue').default,TheNavbarBody: require('/home/runner/work/gg_code/gg_code/nuxt/components/the/TheNavbarBody.vue').default,GSidePanel: require('/home/runner/work/gg_code/gg_code/nuxt/components/GSidePanel.vue').default,GDialogOrMenu: require('/home/runner/work/gg_code/gg_code/nuxt/components/GDialogOrMenu.vue').default,AccountLoginOrSignup: require('/home/runner/work/gg_code/gg_code/nuxt/components/account/AccountLoginOrSignup.vue').default,GModal: require('/home/runner/work/gg_code/gg_code/nuxt/components/GModal.vue').default})
