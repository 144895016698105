









import useI18n from '@/composables/use-i18n'
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: ['error'],
  setup () {
    const i18n = useI18n()

    const img = require(`@/assets/img/error/404_${i18n.locale}.png`)

    return {
      img,
    }
  },
})
