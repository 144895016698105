import { render, staticRenderFns } from "./timer.vue?vue&type=template&id=2f8b8e36&lang=pug&"
import script from "./timer.vue?vue&type=script&lang=ts&"
export * from "./timer.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TheNavbar: require('/home/runner/work/gg_code/gg_code/nuxt/components/the/TheNavbar.vue').default,TheFooter: require('/home/runner/work/gg_code/gg_code/nuxt/components/the/TheFooter.vue').default,GRefreshSearchModal: require('/home/runner/work/gg_code/gg_code/nuxt/components/GRefreshSearchModal.vue').default,CookieInfo: require('/home/runner/work/gg_code/gg_code/nuxt/components/CookieInfo.vue').default,GView: require('/home/runner/work/gg_code/gg_code/nuxt/components/GView.vue').default})
