import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3742424f = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _ec4ab43c = () => interopDefault(import('../pages/corporate.vue' /* webpackChunkName: "pages/corporate" */))
const _13690cd6 = () => interopDefault(import('../pages/embed.vue' /* webpackChunkName: "pages/embed" */))
const _bbefb402 = () => interopDefault(import('../pages/naha.vue' /* webpackChunkName: "pages/naha" */))
const _110f71b6 = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _5cc4e0fb = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _79a66d8b = () => interopDefault(import('../pages/search/city/_brief.vue' /* webpackChunkName: "pages/search/city/_brief" */))
const _0e801128 = () => interopDefault(import('../pages/about/cancelTerms.vue' /* webpackChunkName: "pages/about/cancelTerms" */))
const _249828de = () => interopDefault(import('../pages/about/faq.vue' /* webpackChunkName: "pages/about/faq" */))
const _52a7c155 = () => interopDefault(import('../pages/about/gogoout_promise.vue' /* webpackChunkName: "pages/about/gogoout_promise" */))
const _64d68bf4 = () => interopDefault(import('../pages/about/omnichat.vue' /* webpackChunkName: "pages/about/omnichat" */))
const _db2334d4 = () => interopDefault(import('../pages/about/promise.vue' /* webpackChunkName: "pages/about/promise" */))
const _59892b8e = () => interopDefault(import('../pages/about/recruit.vue' /* webpackChunkName: "pages/about/recruit" */))
const _b3761e1a = () => interopDefault(import('../pages/about/us.vue' /* webpackChunkName: "pages/about/us" */))
const _4e0e0fd2 = () => interopDefault(import('../pages/car/questionnaireReturnCar/index.vue' /* webpackChunkName: "pages/car/questionnaireReturnCar/index" */))
const _90761ed4 = () => interopDefault(import('../pages/car/search.vue' /* webpackChunkName: "pages/car/search" */))
const _8f09a3d6 = () => interopDefault(import('../pages/car/searchHertz.vue' /* webpackChunkName: "pages/car/searchHertz" */))
const _92c49780 = () => interopDefault(import('../pages/car/searchJapan.vue' /* webpackChunkName: "pages/car/searchJapan" */))
const _7cd2200b = () => interopDefault(import('../pages/member/account.vue' /* webpackChunkName: "pages/member/account" */))
const _a7b56234 = () => interopDefault(import('../pages/member/check.vue' /* webpackChunkName: "pages/member/check" */))
const _2de940c7 = () => interopDefault(import('../pages/member/login.vue' /* webpackChunkName: "pages/member/login" */))
const _7d0c5e2c = () => interopDefault(import('../pages/member/order.vue' /* webpackChunkName: "pages/member/order" */))
const _76ab3b2f = () => interopDefault(import('../pages/member/rating.vue' /* webpackChunkName: "pages/member/rating" */))
const _0d040bad = () => interopDefault(import('../pages/member/reset.vue' /* webpackChunkName: "pages/member/reset" */))
const _48ce75ac = () => interopDefault(import('../pages/member/signup.vue' /* webpackChunkName: "pages/member/signup" */))
const _4b4fef08 = () => interopDefault(import('../pages/car/questionnaireReturnCar/appreciate.vue' /* webpackChunkName: "pages/car/questionnaireReturnCar/appreciate" */))
const _8e4bf712 = () => interopDefault(import('../pages/car/questionnaireReturnCar/invalid.vue' /* webpackChunkName: "pages/car/questionnaireReturnCar/invalid" */))
const _06926c6c = () => interopDefault(import('../pages/member/apple/callback.vue' /* webpackChunkName: "pages/member/apple/callback" */))
const _b8cbe21e = () => interopDefault(import('../pages/member/apple/error.vue' /* webpackChunkName: "pages/member/apple/error" */))
const _30509968 = () => interopDefault(import('../pages/member/facebook/callback.vue' /* webpackChunkName: "pages/member/facebook/callback" */))
const _35553411 = () => interopDefault(import('../pages/member/facebook/error.vue' /* webpackChunkName: "pages/member/facebook/error" */))
const _2fb43c39 = () => interopDefault(import('../pages/member/google/callback.vue' /* webpackChunkName: "pages/member/google/callback" */))
const _611e1a44 = () => interopDefault(import('../pages/member/google/error.vue' /* webpackChunkName: "pages/member/google/error" */))
const _394f746d = () => interopDefault(import('../pages/car/confirm/_id.vue' /* webpackChunkName: "pages/car/confirm/_id" */))
const _a28795a0 = () => interopDefault(import('../pages/car/confirm/_id/index.vue' /* webpackChunkName: "pages/car/confirm/_id/index" */))
const _12c03334 = () => interopDefault(import('../pages/car/confirm/_id/check.vue' /* webpackChunkName: "pages/car/confirm/_id/check" */))
const _6c00db86 = () => interopDefault(import('../pages/car/confirm/_id/pay.vue' /* webpackChunkName: "pages/car/confirm/_id/pay" */))
const _33fef4c1 = () => interopDefault(import('../pages/car/confirmHertz/_vendor.vue' /* webpackChunkName: "pages/car/confirmHertz/_vendor" */))
const _23572684 = () => interopDefault(import('../pages/car/confirmHertz/_vendor/index.vue' /* webpackChunkName: "pages/car/confirmHertz/_vendor/index" */))
const _6b3ad7ba = () => interopDefault(import('../pages/car/confirmHertz/_vendor/check.vue' /* webpackChunkName: "pages/car/confirmHertz/_vendor/check" */))
const _f0dfdc4c = () => interopDefault(import('../pages/car/confirmHertz/_vendor/pay.vue' /* webpackChunkName: "pages/car/confirmHertz/_vendor/pay" */))
const _1b1d4c3f = () => interopDefault(import('../pages/car/confirmJapan/_id.vue' /* webpackChunkName: "pages/car/confirmJapan/_id" */))
const _03696782 = () => interopDefault(import('../pages/car/confirmJapan/_id/index.vue' /* webpackChunkName: "pages/car/confirmJapan/_id/index" */))
const _4b4d18b8 = () => interopDefault(import('../pages/car/confirmJapan/_id/check.vue' /* webpackChunkName: "pages/car/confirmJapan/_id/check" */))
const _fecb0b50 = () => interopDefault(import('../pages/car/confirmJapan/_id/pay.vue' /* webpackChunkName: "pages/car/confirmJapan/_id/pay" */))
const _bde82d72 = () => interopDefault(import('../pages/car/order_result/_id.vue' /* webpackChunkName: "pages/car/order_result/_id" */))
const _4e357498 = () => interopDefault(import('../pages/suggestion/country/_country.vue' /* webpackChunkName: "pages/suggestion/country/_country" */))
const _0012fd45 = () => interopDefault(import('../pages/about/_slug.vue' /* webpackChunkName: "pages/about/_slug" */))
const _4d160a0a = () => interopDefault(import('../pages/collection/_type.vue' /* webpackChunkName: "pages/collection/_type" */))
const _439df3c7 = () => interopDefault(import('../pages/comment/_id.vue' /* webpackChunkName: "pages/comment/_id" */))
const _4b2d2189 = () => interopDefault(import('../pages/store/_id.vue' /* webpackChunkName: "pages/store/_id" */))
const _2cb21892 = () => interopDefault(import('../pages/suggestion/_city.vue' /* webpackChunkName: "pages/suggestion/_city" */))
const _f579b2e6 = () => interopDefault(import('../pages/appledeeplink/_.vue' /* webpackChunkName: "pages/appledeeplink/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _3742424f,
    name: "index___en"
  }, {
    path: "/ja",
    component: _3742424f,
    name: "index___ja"
  }, {
    path: "/zh-tw",
    component: _3742424f,
    name: "index___zh-tw"
  }, {
    path: "/en/corporate",
    component: _ec4ab43c,
    name: "corporate___en"
  }, {
    path: "/en/embed",
    component: _13690cd6,
    name: "embed___en"
  }, {
    path: "/en/naha",
    component: _bbefb402,
    name: "naha___en"
  }, {
    path: "/en/register",
    component: _110f71b6,
    name: "register___en"
  }, {
    path: "/en/search",
    component: _5cc4e0fb,
    name: "search___en",
    children: [{
      path: "city/:brief?",
      component: _79a66d8b,
      name: "search-city-brief___en"
    }]
  }, {
    path: "/ja/corporate",
    component: _ec4ab43c,
    name: "corporate___ja"
  }, {
    path: "/ja/embed",
    component: _13690cd6,
    name: "embed___ja"
  }, {
    path: "/ja/naha",
    component: _bbefb402,
    name: "naha___ja"
  }, {
    path: "/ja/register",
    component: _110f71b6,
    name: "register___ja"
  }, {
    path: "/ja/search",
    component: _5cc4e0fb,
    name: "search___ja",
    children: [{
      path: "city/:brief?",
      component: _79a66d8b,
      name: "search-city-brief___ja"
    }]
  }, {
    path: "/zh-tw/corporate",
    component: _ec4ab43c,
    name: "corporate___zh-tw"
  }, {
    path: "/zh-tw/embed",
    component: _13690cd6,
    name: "embed___zh-tw"
  }, {
    path: "/zh-tw/naha",
    component: _bbefb402,
    name: "naha___zh-tw"
  }, {
    path: "/zh-tw/register",
    component: _110f71b6,
    name: "register___zh-tw"
  }, {
    path: "/zh-tw/search",
    component: _5cc4e0fb,
    name: "search___zh-tw",
    children: [{
      path: "city/:brief?",
      component: _79a66d8b,
      name: "search-city-brief___zh-tw"
    }]
  }, {
    path: "/en/about/cancelTerms",
    component: _0e801128,
    name: "about-cancelTerms___en"
  }, {
    path: "/en/about/faq",
    component: _249828de,
    name: "about-faq___en"
  }, {
    path: "/en/about/gogoout_promise",
    component: _52a7c155,
    name: "about-gogoout_promise___en"
  }, {
    path: "/en/about/omnichat",
    component: _64d68bf4,
    name: "about-omnichat___en"
  }, {
    path: "/en/about/promise",
    component: _db2334d4,
    name: "about-promise___en"
  }, {
    path: "/en/about/recruit",
    component: _59892b8e,
    name: "about-recruit___en"
  }, {
    path: "/en/about/us",
    component: _b3761e1a,
    name: "about-us___en"
  }, {
    path: "/en/car/questionnaireReturnCar",
    component: _4e0e0fd2,
    name: "car-questionnaireReturnCar___en"
  }, {
    path: "/en/car/search",
    component: _90761ed4,
    name: "car-search___en"
  }, {
    path: "/en/car/searchHertz",
    component: _8f09a3d6,
    name: "car-searchHertz___en"
  }, {
    path: "/en/car/searchJapan",
    component: _92c49780,
    name: "car-searchJapan___en"
  }, {
    path: "/en/member/account",
    component: _7cd2200b,
    name: "member-account___en"
  }, {
    path: "/en/member/check",
    component: _a7b56234,
    name: "member-check___en"
  }, {
    path: "/en/member/login",
    component: _2de940c7,
    name: "member-login___en"
  }, {
    path: "/en/member/order",
    component: _7d0c5e2c,
    name: "member-order___en"
  }, {
    path: "/en/member/rating",
    component: _76ab3b2f,
    name: "member-rating___en"
  }, {
    path: "/en/member/reset",
    component: _0d040bad,
    name: "member-reset___en"
  }, {
    path: "/en/member/signup",
    component: _48ce75ac,
    name: "member-signup___en"
  }, {
    path: "/ja/about/cancelTerms",
    component: _0e801128,
    name: "about-cancelTerms___ja"
  }, {
    path: "/ja/about/faq",
    component: _249828de,
    name: "about-faq___ja"
  }, {
    path: "/ja/about/gogoout_promise",
    component: _52a7c155,
    name: "about-gogoout_promise___ja"
  }, {
    path: "/ja/about/omnichat",
    component: _64d68bf4,
    name: "about-omnichat___ja"
  }, {
    path: "/ja/about/promise",
    component: _db2334d4,
    name: "about-promise___ja"
  }, {
    path: "/ja/about/recruit",
    component: _59892b8e,
    name: "about-recruit___ja"
  }, {
    path: "/ja/about/us",
    component: _b3761e1a,
    name: "about-us___ja"
  }, {
    path: "/ja/car/questionnaireReturnCar",
    component: _4e0e0fd2,
    name: "car-questionnaireReturnCar___ja"
  }, {
    path: "/ja/car/search",
    component: _90761ed4,
    name: "car-search___ja"
  }, {
    path: "/ja/car/searchHertz",
    component: _8f09a3d6,
    name: "car-searchHertz___ja"
  }, {
    path: "/ja/car/searchJapan",
    component: _92c49780,
    name: "car-searchJapan___ja"
  }, {
    path: "/ja/member/account",
    component: _7cd2200b,
    name: "member-account___ja"
  }, {
    path: "/ja/member/check",
    component: _a7b56234,
    name: "member-check___ja"
  }, {
    path: "/ja/member/login",
    component: _2de940c7,
    name: "member-login___ja"
  }, {
    path: "/ja/member/order",
    component: _7d0c5e2c,
    name: "member-order___ja"
  }, {
    path: "/ja/member/rating",
    component: _76ab3b2f,
    name: "member-rating___ja"
  }, {
    path: "/ja/member/reset",
    component: _0d040bad,
    name: "member-reset___ja"
  }, {
    path: "/ja/member/signup",
    component: _48ce75ac,
    name: "member-signup___ja"
  }, {
    path: "/zh-tw/about/cancelTerms",
    component: _0e801128,
    name: "about-cancelTerms___zh-tw"
  }, {
    path: "/zh-tw/about/faq",
    component: _249828de,
    name: "about-faq___zh-tw"
  }, {
    path: "/zh-tw/about/gogoout_promise",
    component: _52a7c155,
    name: "about-gogoout_promise___zh-tw"
  }, {
    path: "/zh-tw/about/omnichat",
    component: _64d68bf4,
    name: "about-omnichat___zh-tw"
  }, {
    path: "/zh-tw/about/promise",
    component: _db2334d4,
    name: "about-promise___zh-tw"
  }, {
    path: "/zh-tw/about/recruit",
    component: _59892b8e,
    name: "about-recruit___zh-tw"
  }, {
    path: "/zh-tw/about/us",
    component: _b3761e1a,
    name: "about-us___zh-tw"
  }, {
    path: "/zh-tw/car/questionnaireReturnCar",
    component: _4e0e0fd2,
    name: "car-questionnaireReturnCar___zh-tw"
  }, {
    path: "/zh-tw/car/search",
    component: _90761ed4,
    name: "car-search___zh-tw"
  }, {
    path: "/zh-tw/car/searchHertz",
    component: _8f09a3d6,
    name: "car-searchHertz___zh-tw"
  }, {
    path: "/zh-tw/car/searchJapan",
    component: _92c49780,
    name: "car-searchJapan___zh-tw"
  }, {
    path: "/zh-tw/member/account",
    component: _7cd2200b,
    name: "member-account___zh-tw"
  }, {
    path: "/zh-tw/member/check",
    component: _a7b56234,
    name: "member-check___zh-tw"
  }, {
    path: "/zh-tw/member/login",
    component: _2de940c7,
    name: "member-login___zh-tw"
  }, {
    path: "/zh-tw/member/order",
    component: _7d0c5e2c,
    name: "member-order___zh-tw"
  }, {
    path: "/zh-tw/member/rating",
    component: _76ab3b2f,
    name: "member-rating___zh-tw"
  }, {
    path: "/zh-tw/member/reset",
    component: _0d040bad,
    name: "member-reset___zh-tw"
  }, {
    path: "/zh-tw/member/signup",
    component: _48ce75ac,
    name: "member-signup___zh-tw"
  }, {
    path: "/en/car/questionnaireReturnCar/appreciate",
    component: _4b4fef08,
    name: "car-questionnaireReturnCar-appreciate___en"
  }, {
    path: "/en/car/questionnaireReturnCar/invalid",
    component: _8e4bf712,
    name: "car-questionnaireReturnCar-invalid___en"
  }, {
    path: "/en/member/apple/callback",
    component: _06926c6c,
    name: "member-apple-callback___en"
  }, {
    path: "/en/member/apple/error",
    component: _b8cbe21e,
    name: "member-apple-error___en"
  }, {
    path: "/en/member/facebook/callback",
    component: _30509968,
    name: "member-facebook-callback___en"
  }, {
    path: "/en/member/facebook/error",
    component: _35553411,
    name: "member-facebook-error___en"
  }, {
    path: "/en/member/google/callback",
    component: _2fb43c39,
    name: "member-google-callback___en"
  }, {
    path: "/en/member/google/error",
    component: _611e1a44,
    name: "member-google-error___en"
  }, {
    path: "/ja/car/questionnaireReturnCar/appreciate",
    component: _4b4fef08,
    name: "car-questionnaireReturnCar-appreciate___ja"
  }, {
    path: "/ja/car/questionnaireReturnCar/invalid",
    component: _8e4bf712,
    name: "car-questionnaireReturnCar-invalid___ja"
  }, {
    path: "/ja/member/apple/callback",
    component: _06926c6c,
    name: "member-apple-callback___ja"
  }, {
    path: "/ja/member/apple/error",
    component: _b8cbe21e,
    name: "member-apple-error___ja"
  }, {
    path: "/ja/member/facebook/callback",
    component: _30509968,
    name: "member-facebook-callback___ja"
  }, {
    path: "/ja/member/facebook/error",
    component: _35553411,
    name: "member-facebook-error___ja"
  }, {
    path: "/ja/member/google/callback",
    component: _2fb43c39,
    name: "member-google-callback___ja"
  }, {
    path: "/ja/member/google/error",
    component: _611e1a44,
    name: "member-google-error___ja"
  }, {
    path: "/zh-tw/car/questionnaireReturnCar/appreciate",
    component: _4b4fef08,
    name: "car-questionnaireReturnCar-appreciate___zh-tw"
  }, {
    path: "/zh-tw/car/questionnaireReturnCar/invalid",
    component: _8e4bf712,
    name: "car-questionnaireReturnCar-invalid___zh-tw"
  }, {
    path: "/zh-tw/member/apple/callback",
    component: _06926c6c,
    name: "member-apple-callback___zh-tw"
  }, {
    path: "/zh-tw/member/apple/error",
    component: _b8cbe21e,
    name: "member-apple-error___zh-tw"
  }, {
    path: "/zh-tw/member/facebook/callback",
    component: _30509968,
    name: "member-facebook-callback___zh-tw"
  }, {
    path: "/zh-tw/member/facebook/error",
    component: _35553411,
    name: "member-facebook-error___zh-tw"
  }, {
    path: "/zh-tw/member/google/callback",
    component: _2fb43c39,
    name: "member-google-callback___zh-tw"
  }, {
    path: "/zh-tw/member/google/error",
    component: _611e1a44,
    name: "member-google-error___zh-tw"
  }, {
    path: "/en/car/confirm/:id?",
    component: _394f746d,
    children: [{
      path: "",
      component: _a28795a0,
      name: "car-confirm-id___en"
    }, {
      path: "check",
      component: _12c03334,
      name: "car-confirm-id-check___en"
    }, {
      path: "pay",
      component: _6c00db86,
      name: "car-confirm-id-pay___en"
    }]
  }, {
    path: "/en/car/confirmHertz/:vendor?",
    component: _33fef4c1,
    children: [{
      path: "",
      component: _23572684,
      name: "car-confirmHertz-vendor___en"
    }, {
      path: "check",
      component: _6b3ad7ba,
      name: "car-confirmHertz-vendor-check___en"
    }, {
      path: "pay",
      component: _f0dfdc4c,
      name: "car-confirmHertz-vendor-pay___en"
    }]
  }, {
    path: "/en/car/confirmJapan/:id?",
    component: _1b1d4c3f,
    children: [{
      path: "",
      component: _03696782,
      name: "car-confirmJapan-id___en"
    }, {
      path: "check",
      component: _4b4d18b8,
      name: "car-confirmJapan-id-check___en"
    }, {
      path: "pay",
      component: _fecb0b50,
      name: "car-confirmJapan-id-pay___en"
    }]
  }, {
    path: "/en/car/order_result/:id?",
    component: _bde82d72,
    name: "car-order_result-id___en"
  }, {
    path: "/en/suggestion/country/:country?",
    component: _4e357498,
    name: "suggestion-country-country___en"
  }, {
    path: "/ja/car/confirm/:id?",
    component: _394f746d,
    children: [{
      path: "",
      component: _a28795a0,
      name: "car-confirm-id___ja"
    }, {
      path: "check",
      component: _12c03334,
      name: "car-confirm-id-check___ja"
    }, {
      path: "pay",
      component: _6c00db86,
      name: "car-confirm-id-pay___ja"
    }]
  }, {
    path: "/ja/car/confirmHertz/:vendor?",
    component: _33fef4c1,
    children: [{
      path: "",
      component: _23572684,
      name: "car-confirmHertz-vendor___ja"
    }, {
      path: "check",
      component: _6b3ad7ba,
      name: "car-confirmHertz-vendor-check___ja"
    }, {
      path: "pay",
      component: _f0dfdc4c,
      name: "car-confirmHertz-vendor-pay___ja"
    }]
  }, {
    path: "/ja/car/confirmJapan/:id?",
    component: _1b1d4c3f,
    children: [{
      path: "",
      component: _03696782,
      name: "car-confirmJapan-id___ja"
    }, {
      path: "check",
      component: _4b4d18b8,
      name: "car-confirmJapan-id-check___ja"
    }, {
      path: "pay",
      component: _fecb0b50,
      name: "car-confirmJapan-id-pay___ja"
    }]
  }, {
    path: "/ja/car/order_result/:id?",
    component: _bde82d72,
    name: "car-order_result-id___ja"
  }, {
    path: "/ja/suggestion/country/:country?",
    component: _4e357498,
    name: "suggestion-country-country___ja"
  }, {
    path: "/zh-tw/car/confirm/:id?",
    component: _394f746d,
    children: [{
      path: "",
      component: _a28795a0,
      name: "car-confirm-id___zh-tw"
    }, {
      path: "check",
      component: _12c03334,
      name: "car-confirm-id-check___zh-tw"
    }, {
      path: "pay",
      component: _6c00db86,
      name: "car-confirm-id-pay___zh-tw"
    }]
  }, {
    path: "/zh-tw/car/confirmHertz/:vendor?",
    component: _33fef4c1,
    children: [{
      path: "",
      component: _23572684,
      name: "car-confirmHertz-vendor___zh-tw"
    }, {
      path: "check",
      component: _6b3ad7ba,
      name: "car-confirmHertz-vendor-check___zh-tw"
    }, {
      path: "pay",
      component: _f0dfdc4c,
      name: "car-confirmHertz-vendor-pay___zh-tw"
    }]
  }, {
    path: "/zh-tw/car/confirmJapan/:id?",
    component: _1b1d4c3f,
    children: [{
      path: "",
      component: _03696782,
      name: "car-confirmJapan-id___zh-tw"
    }, {
      path: "check",
      component: _4b4d18b8,
      name: "car-confirmJapan-id-check___zh-tw"
    }, {
      path: "pay",
      component: _fecb0b50,
      name: "car-confirmJapan-id-pay___zh-tw"
    }]
  }, {
    path: "/zh-tw/car/order_result/:id?",
    component: _bde82d72,
    name: "car-order_result-id___zh-tw"
  }, {
    path: "/zh-tw/suggestion/country/:country?",
    component: _4e357498,
    name: "suggestion-country-country___zh-tw"
  }, {
    path: "/en/about/:slug?",
    component: _0012fd45,
    name: "about-slug___en"
  }, {
    path: "/en/collection/:type?",
    component: _4d160a0a,
    name: "collection-type___en"
  }, {
    path: "/en/comment/:id?",
    component: _439df3c7,
    name: "comment-id___en"
  }, {
    path: "/en/store/:id?",
    component: _4b2d2189,
    name: "store-id___en"
  }, {
    path: "/en/suggestion/:city?",
    component: _2cb21892,
    name: "suggestion-city___en"
  }, {
    path: "/ja/about/:slug?",
    component: _0012fd45,
    name: "about-slug___ja"
  }, {
    path: "/ja/collection/:type?",
    component: _4d160a0a,
    name: "collection-type___ja"
  }, {
    path: "/ja/comment/:id?",
    component: _439df3c7,
    name: "comment-id___ja"
  }, {
    path: "/ja/store/:id?",
    component: _4b2d2189,
    name: "store-id___ja"
  }, {
    path: "/ja/suggestion/:city?",
    component: _2cb21892,
    name: "suggestion-city___ja"
  }, {
    path: "/zh-tw/about/:slug?",
    component: _0012fd45,
    name: "about-slug___zh-tw"
  }, {
    path: "/zh-tw/collection/:type?",
    component: _4d160a0a,
    name: "collection-type___zh-tw"
  }, {
    path: "/zh-tw/comment/:id?",
    component: _439df3c7,
    name: "comment-id___zh-tw"
  }, {
    path: "/zh-tw/store/:id?",
    component: _4b2d2189,
    name: "store-id___zh-tw"
  }, {
    path: "/zh-tw/suggestion/:city?",
    component: _2cb21892,
    name: "suggestion-city___zh-tw"
  }, {
    path: "/ja/appledeeplink/*",
    component: _f579b2e6,
    name: "appledeeplink-all___ja"
  }, {
    path: "/en/appledeeplink/*",
    component: _f579b2e6,
    name: "appledeeplink-all___en"
  }, {
    path: "/zh-tw/appledeeplink/*",
    component: _f579b2e6,
    name: "appledeeplink-all___zh-tw"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
