


















import { defineComponent, PropType } from '@vue/composition-api'
import Order from '@/classes/Order'
import useOrderListData from '@/composables/use-order-list-data'

export default defineComponent({
  props: {
    order: {
      type: Object as PropType<Order>,
      required: true,
    },
  },
  setup (props, { emit }) {
    const {
      orderTime,
      productName,
      startStoreLink,
      startTime,
      endTime,
      price,

      checkOrder,
    } = useOrderListData(props.order, emit)

    return {
      orderTime,
      productName,
      startStoreLink,
      startTime,
      endTime,
      price,

      checkOrder,
    }
  },
})
