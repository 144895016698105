













import { defineComponent } from '@nuxtjs/composition-api'

import useDefaultMeta from '@/composables/use-default-meta'
import useI18n from '@/composables/use-i18n'
import useLayoutCommon from '@/composables/use-layout-common'

export default defineComponent({
  props: ['error'],
  setup () {
    const i18n = useI18n()
    useDefaultMeta({
      title: String(i18n.t('head.index.title')),
      description: String(i18n.t('head.index.description')),
    })
    useLayoutCommon()
  },
})
