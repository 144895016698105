//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api'

import useI18n from '@/composables/use-i18n'

import ThirdParty from '@/enums/ThirdParty'
import useNuxtImage from '@/composables/use-nuxt-image'

export default defineComponent({
  props: {
    from: {
      type: String,
      default: '',
    },
  },
  setup (props) {
    const i18n = useI18n()
    let img, imgSources, title
    switch (props.from) {
      case ThirdParty.GOOGLE:
        img = 'login/google.png'
        imgSources = ['login/google.webp', 'login/google.png']
        title = i18n.t('user.login.withGoogle')
        break
      case ThirdParty.APPLE:
        img = 'login/apple.png'
        imgSources = ['login/apple.webp', 'login/apple.png']
        title = i18n.t('user.login.withApple')
        break
      case ThirdParty.FACEBOOK:
        img = 'login/facebook.png'
        imgSources = ['login/facebook.webp', 'login/facebook.png']
        title = i18n.t('user.login.withFacebook')
        break
    }

    const imgProps = useNuxtImage({
      placeholder: true,
      sizes: 'sm:20px',
      imgAttrs: {
        class: 'max-h-5 w-auto',
        alt: title.toString(),
      },
    }).props

    return {
      img,
      imgSources,
      title,
      imgProps,
    }
  },
})
