import { render, staticRenderFns } from "./GDialogOrMenu.vue?vue&type=template&id=3209a318&lang=pug&"
import script from "./GDialogOrMenu.vue?vue&type=script&lang=ts&"
export * from "./GDialogOrMenu.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GDialog: require('/home/runner/work/gg_code/gg_code/nuxt/components/GDialog.vue').default,GMenu: require('/home/runner/work/gg_code/gg_code/nuxt/components/GMenu.vue').default})
