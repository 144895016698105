















import { defineComponent, onMounted } from '@nuxtjs/composition-api'
import { LogName } from '~/interfaces/Logger'
import ggoLogger from '~/modules/ggo-logger'

export default defineComponent({
  props: {
    title: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
  },
  setup (props, { emit }) {
    const img = require('@/assets/img/timeout_artBoard.webp')

    const search = () => {
      emit('refresh')
    }

    onMounted(() => {
      ggoLogger.info(LogName.TOAST, {
        type: 'danger',
        message: props.content,
      })
    })

    return {
      img,
      search,
    }
  },
})
