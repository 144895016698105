

























































import { ComputedRef, computed, defineComponent, useContext } from '@nuxtjs/composition-api'
import useI18n from '@/composables/use-i18n'

import useNuxtImage from '@/composables/use-nuxt-image'
import { tdFooterCompany, tdFooterSupplier, tdFooterCustomer, tdFooterMedia, tdFooterService, tdFooterFollowUs } from '@/modules/ga4'

type Payment = {
  img: string;
  imgSources?: Array<string>;
  alt?: string;
}

export default defineComponent({
  setup () {
    const { app: { localePath } } = useContext()
    const i18n = useI18n()
    const contactList = computed(() => [
      {
        name: i18n.t('label.address'),
        value: i18n.t('gogoout.address'),
        link: 'https://goo.gl/maps/jPBiz1PKn5PyGkUa8',
      },
      {
        name: i18n.t('label.telephone'),
        value: i18n.t('gogoout.telephone'),
        link: 'tel:+886277523598',
      },
    ])

    const links = computed(() => [
      {
        title: i18n.t('footer.title.gogoout'),
        className: 'td_footer_company',
        links: [
          {
            name: i18n.t('link.aboutUs'),
            to: localePath('/about/us'),
            onClick: tdFooterCompany,
          },
          {
            name: i18n.t('link.careers'),
            link: 'https://www.104.com.tw/jobbank/custjob/index.php?r=cust&j=414b436c35373f6831333b64393f371a72a2a2a6e4147592674j49',
            target: '_blank',
            onClick: tdFooterCompany,
          },
        ],
      },
      {
        title: i18n.t('footer.title.supplier'),
        className: 'td_footer_supplier',
        links: [
          {
            name: i18n.t('link.manageBookings'),
            link: localePath('/storeAdmin/login'),
            rel: 'noindex, nofollow',
            onClick: tdFooterSupplier,
          },
          {
            name: i18n.t('link.becomeSupplier'),
            to: localePath('/about/recruit'),
            onClick: tdFooterSupplier,
          },
        ],
      },
      {
        title: i18n.t('footer.title.customer'),
        className: 'td_footer_customer',
        links: [
          {
            name: i18n.t('link.rentalStatement'),
            to: localePath('/about/description'),
            onClick: tdFooterCustomer,
          },
          {
            name: i18n.t('link.faqs'),
            to: localePath('/about/faq'),
            onClick: tdFooterCustomer,
          },
          {
            name: i18n.t('link.comment'),
            to: localePath('/about/promise'),
            onClick: tdFooterCustomer,
          },
        ],
      },
      {
        title: i18n.t('footer.title.media'),
        className: 'td_footer_media',
        links: [
          {
            name: i18n.t('link.businessNext'),
            link: 'https://www.bnext.com.tw/article/73578/gogoout-jan-mag',
            target: '_blank',
            onClick: tdFooterMedia,
          },
          {
            name: i18n.t('link.theStormMedia'),
            link: 'https://www.storm.mg/lifestyle/4649251?page=1',
            target: '_blank',
            onClick: tdFooterMedia,
          },
        ],
      },
      {
        title: i18n.t('footer.title.service'),
        className: 'td_footer_service',
        links: [
          {
            name: i18n.t('link.termsOfUse'),
            to: localePath('/about/terms'),
            onClick: tdFooterService,
          },
          {
            name: i18n.t('link.privacyPolicy'),
            to: localePath('/about/privacy'),
            onClick: tdFooterService,
          },
        ],
      },
    ])

    const payments: ComputedRef<Array<Payment>> = computed(() => [
      {
        img: 'payment/line-pay.png',
        imgSources: ['payment/line-pay.webp', 'payment/line-pay.png'],
        alt: i18n.t('footer.paymentMethod.linePay.alt').toString(),
      },
      {
        img: 'payment/newebpay.png',
        imgSources: ['payment/newebpay.webp', 'payment/newebpay.png'],
        alt: i18n.t('footer.paymentMethod.newebpay.alt').toString(),
      },
      {
        img: 'payment/ssl.svg',
        alt: i18n.t('footer.paymentMethod.ssl.alt').toString(),
      },
      {
        img: 'payment/tap-pay.png',
        imgSources: ['payment/tap-pay.webp', 'payment/tap-pay.png'],
        alt: i18n.t('footer.paymentMethod.tappay.alt').toString(),
      },
    ])

    const getPaymentImgProps = (payment: Payment) => useNuxtImage({
      placeholder: true,
      sizes: 'sm:80px',
      imgAttrs: {
        class: 'w-auto h-7',
        alt: payment.alt,
      },
      src: payment.img,
      sources: payment.imgSources,
    }).props

    const { props: logoProps } = useNuxtImage({
      placeholder: true,
      width: 136,
      imgAttrs: {
        class: 'w-34 mb-6',
        alt: 'gogoout logo',
      },
      src: 'logo.png',
      sources: ['logo.webp', 'logo.png'],
    })

    const onSocialMediaItemClick = (name: string) => {
      tdFooterFollowUs(i18n.t('footer.title.followUs').toString(), name)
    }

    return {
      contactList,
      links,
      payments,
      logoProps,
      getPaymentImgProps,
      onSocialMediaItemClick,
    }
  },
})
