import { computed, onMounted, ref } from '@nuxtjs/composition-api'
import Order from '@/classes/Order'
import Vendor from '@/enums/Vendor'
import Price from '@/classes/Price'
import useReferralAds from '@/composables/use-referral-ads'
import useI18n from '@/composables/use-i18n'
import useOrder from '@/composables/use-order'
import HertzCar from '@/classes/Hertz/HertzCar'
import ggoApi from '@/modules/ggo-api'
import Store from '@/classes/Store'
import useConfirmSubmit from '@/composables/use-confirm-submit'
import PaymentMethod from '@/enums/PaymentMethod'
import useAftee from '@/composables/use-aftee'

export default function (order: Order, emit?: Function) {
  const i18n = useI18n()
  const { getInsuranceInfo } = useReferralAds()
  const { getStore, getRefundPercentage } = useOrder()

  // 訂購時間
  const orderTime = computed(() => order.orderTime ?? '-')

  // 車款
  const productName = computed(() => order.productName ?? '-')

  // 取車商家
  const startStoreLink = computed(() => ({
    value: order.startStoreName,
    link: order.startStoreId !== Vendor.HERTZ && order.startStoreId ? getStore(String(order.startStoreId)) : null,
  }))

  // 取車時間
  const startTime = computed(() => order.startTime ?? '-')

  // 還車時間
  const endTime = computed(() => order.endTime ?? '-')

  // 金額
  const price = computed(() => order.price ? new Price(order.price.value, order.price.currency).fullFormattedValue ?? '-' : '-')

  // 保險
  const insurances = computed(() => {
    if (order.referralAds && order.referralAds.length) {
      return order.referralAds.map(item => ({
        value: i18n.t('action.check', [getInsuranceInfo(item).name]),
        link: getInsuranceInfo(item).href,
      }))
    }
    return null
  })

  // 退款比例
  const refundRate = computed(() => order.refundPercentage ? getRefundPercentage(order.refundPercentage) : '-')

  // 退款金額
  const refund = computed(() => {
    if (order.refund) return new Price(order.refund.value, order.refund.currency).fullFormattedValue ?? '-'
    else if (order.price) return new Price(order.price.value, order.price.currency).fullFormattedValue ?? '-'
    else return '-'
  })

  // Actions
  const cancel = () => {
    emit && emit('cancel', order.orderNum, order.vendor)
  }

  const resendEmail = () => {
    emit && emit('resend-email', order.orderNum, order.vendor)
  }

  const changeInvoice = () => {
    emit && emit('change-invoice', order.orderNum)
  }

  const checkOrder = () => {
    if (order.status !== 'canceled') {
      emit && emit('check-order', order.orderNum, order.vendor)
    }
  }

  const goReview = () => {
    emit && emit('go-review', order.orderNum)
  }

  const checkOrderReview = () => {
    emit && emit('check-order-review', order.orderNum)
  }

  const { setConfig: setAfteeConfig, load: loadAftee, loading: afteeLoading } = useAftee()
  // @ts-ignore
  const { createPayment, goToPaymentPage } = useConfirmSubmit({})
  const payAgain = async (orderNum: string) => {
    const { url, method, data, paymentMethodId } = await createPayment(orderNum)

    // 使用 Aftee 支付
    if (paymentMethodId === PaymentMethod.Aftee) {
      // FIXME: TS2345 https://stackoverflow.com/questions/48924733/why-typescript-enums-doesnt-helps-to-infer-union-type
      setAfteeConfig(data as any, orderNum)
      loadAftee()
    } else {
      if (process.env.NODE_ENV === 'local' || process.env.NODE_ENV === 'testing') {
        alert(`
        這裡是測試區，輸入測試用的信用卡

        Card number: 4000-2211-1111-1111
        Expiration date: 任意填寫
        Security code: 任意填寫
        `.trim())
      }

      // 4. 至付款頁
      // FIXME: TS2345 https://stackoverflow.com/questions/48924733/why-typescript-enums-doesnt-helps-to-infer-union-type
      await goToPaymentPage(url, method, data as any)
    }
  }

  // 需要 call api
  // Store
  const startStore = ref<Store | HertzCar>()
  onMounted(() => {
    ;(async () => {
      if (order.startStoreId !== Vendor.HERTZ) {
        const { success, data } = await ggoApi.getStore({
          id: Number(order.startStoreId),
        })

        if (success && data) {
          startStore.value = new Store(data.store)
        }
      }
    })()
  })

  return {
    orderTime,
    productName,
    startStoreLink,
    startTime,
    endTime,
    price,
    insurances,
    refundRate,
    refund,
    goReview,
    cancel,
    resendEmail,
    changeInvoice,
    payAgain,
    checkOrder,
    checkOrderReview,
    startStore,
    afteeLoading,
  }
}
