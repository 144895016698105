var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('footer',{staticClass:"bg-primary text-white px-4 py-8 lg:pt-6 lg:pb-4"},[_c('div',{staticClass:"container flex items-center justify-center mb-6"},[_c('div',{staticClass:"flex flex-wrap -mx-4 xl:mx-0"},[_c('address',{staticClass:"w-full xl:max-w-52 xl:w-auto xl:mr-7 px-4 xl:px-0 mb-8 xl:mb-0 not-italic"},[_c('NuxtPicture',_vm._b({attrs:{"loading":"lazy"}},'NuxtPicture',_vm.logoProps,false)),_c('dl',{staticClass:"contact space-y-2"},_vm._l((_vm.contactList),function(ref){
var name = ref.name;
var value = ref.value;
var link = ref.link;
return _c('div',[_c('dt',{staticClass:"inline name"},[_vm._v(_vm._s(name))]),_c('dd',{staticClass:"inline"},[_c('a',{staticClass:"link",attrs:{"target":"_blank","href":link}},[_vm._v(_vm._s(value))])])])}),0)],1),_c('section',{staticClass:"block xl:hidden w-full xl:w-auto px-4 mb-16"},[_c('TheFooterSocialMediaList')],1),_vm._l((_vm.links),function(ref){
var title = ref.title;
var className = ref.className;
var links = ref.links;
return _c('section',{staticClass:"w-1/2 xl:w-auto min-w-32 px-4 xl:px-0 xl:pr-8 mb-9 xl:mb-0",class:className},[_c('h3',{staticClass:"title"},[_vm._v(_vm._s(title))]),_c('ul',{staticClass:"space-y-4"},_vm._l((links),function(ref){
var name = ref.name;
var to = ref.to;
var link = ref.link;
var target = ref.target;
var rel = ref.rel;
var onClick = ref.onClick;
return _c('li',{staticClass:"text-sm"},[(to)?_c('NuxtLink',{staticClass:"link",attrs:{"to":to,"rel":rel},nativeOn:{"click":function($event){return onClick(title, name)}}},[_vm._v(_vm._s(name))]):_c('a',{staticClass:"link",attrs:{"href":link || "#","target":target,"rel":rel},on:{"click":function($event){return onClick(title, name)}}},[_vm._v(_vm._s(name))])],1)}),0)])}),_c('div',{staticClass:"flex xl:flex-col flex-wrap w-1/2 xl:w-auto px-4 xl:px-0"},[_c('section',{staticClass:"block xl:block items-center mb-4"},[_c('h3',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('footer.title.paymentMethod')))]),_c('ul',{staticClass:"flex flex-col xl:flex-row flex-wrap xl:items-center"},_vm._l((_vm.payments),function(payment){return _c('li',{staticClass:"mb-1 xl:mb-0 mr-2"},[_c('NuxtPicture',_vm._b({attrs:{"loading":"lazy"}},'NuxtPicture',_vm.getPaymentImgProps(payment),false))],1)}),0)]),_c('section',{staticClass:"td_footer_follow_us hidden xl:block"},[_c('h3',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('footer.title.followUs')))]),_c('TheFooterSocialMediaList',{on:{"item-click":_vm.onSocialMediaItemClick}})],1)])],2)]),_c('p',{staticClass:"text-xs text-center uppercase"},[_vm._v("COPYRIGHT © GOGOOUT CO., LTD "+_vm._s(new Date().getFullYear())+" All rights reserved.")])])}
var staticRenderFns = []

export { render, staticRenderFns }