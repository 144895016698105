/* eslint-disable camelcase */
import { EcommercePurchaseData } from '@/interfaces/DataLayer'
import Cookies from 'js-cookie'
import GgoTrackKey from '@/enums/GgoTrackKey'
import trackerRequest from './request'

declare global {
  interface Window { gaGlobal: any; gtag: any; }
}

type GaEvent = {
  name: string,
  params?: Record<string, any>
}

type UserProperties = {
  crm_id?: {
    value?: number
  },
  td_client_id?: {
    value?: string
  }
}

class GaTracker {
  analytics_id = 'G-TCLST2CPYN'
  user_id?: number
  startTime = new Date().getTime()
  lastEngagementTime = new Date().getTime()

  user_properties: UserProperties = {
    crm_id: undefined,
    td_client_id: undefined,
  }

  eventsQueue: Array<Array<GaEvent>> = []

  constructor () {
    if (!process.server) {
      const interval = setInterval(() => {
        if (window?.gtag) {
          for (const events of this.eventsQueue) {
            this.send(events)
          }

          this.eventsQueue = []
          clearInterval(interval)
        }
      }, 1000)
    }
  }

  /**
   * 獲取 sessionId, 常存在 sessionStorage
   */
  private getGgoSessionId (): string {
    // server side不執行: 拿不到 sessionStorage
    if (!process.server) {
      const sessionId = Cookies.get(GgoTrackKey.SESSION_ID_STORE_KEY)
      return sessionId || ''
    }

    return ''
  }

  /**
   * 獲取 sessionId, 常存在 sessionStorage
   */
  private getGgoDeviceId (): string {
    // server side不執行: 拿不到 sessionStorage
    if (!process.server) {
      const deviceId = Cookies.get(GgoTrackKey.DEVICE_ID_STORE_KEY)
      return deviceId || ''
    }

    return ''
  }

  gtagGet (key: string): Promise<any> {
    return new Promise((resolve) => {
      window.gtag('get', this.analytics_id, key, (value: any) => { resolve(value) })
    })
  }

  getSessionData () {
    // Use your own ID here ↓
    const _id = this.analytics_id.split('-')[1]
    const cookieValue = Cookies.get(`_ga_${_id}`)

    if (!cookieValue) return {}

    const pattern = /GS\d\.\d\.(.+?)(?:;|$)/
    const match = cookieValue.match(pattern)
    const parts = match?.[1].split('.')

    if (!parts) return {}

    return {
      session_id: parts.shift(),
      session_number: parts.shift(),
    }
  }

  async send (events: Array<GaEvent>) {
    if (!process.server) {
      try {
        if (!window?.gtag) {
          this.eventsQueue.push(events)
          return
        }

        /**
       * https://support.google.com/analytics/answer/11109416?hl=zh-Hant
       * 工作階段的起始時間
       * 到新頁面為新的 session
       */
        const client_id: string = await this.gtagGet('client_id')
        const session_id: string = await this.gtagGet('session_id')
        const session_number: number = await this.gtagGet('session_number')
        const engagement_time_msec: number = new Date().getTime() - this.lastEngagementTime
        this.lastEngagementTime = new Date().getTime()

        /**
       * UTM
       * https://www.haranhuang.com/ga4-measurement-protocol-frequently-asked-questions.html
       */
        const utm_params: any = {}
        const url = new URL(window.location.href)
        url.searchParams.forEach((value, key) => {
          if (key.startsWith('utm_')) {
            utm_params[key.replace('utm_', '')] = value
          }
        })

        await trackerRequest.post('', {
          topic: 'ga',
          payload: {
          // https://www.haranhuang.com/setting-client-id-as-a-custom-dimension-in-google-analytics-4.html
            client_id,
            timestamp_micros: new Date().getTime() * 1000,
            user_id: this.user_id ? String(this.user_id) : undefined,
            user_properties: {
              ...this.user_properties,
              td_client_id: {
                value: client_id,
              },
              ggo_session_id: {
                value: this.getGgoSessionId(),
              },
              ggo_device_id: {
                value: this.getGgoDeviceId(),
              },
            },
            events: events.map(event => ({
              ...event,
              ...(event.params && {
                params: {
                  ...event.params,
                  td_hit_timestamp: new Date().getTime() * 1000,
                  batch_page_id: new Date().getTime(),

                  // 使用者活動
                  ggo_session_id: this.getGgoSessionId(),
                  ggo_device_id: this.getGgoDeviceId(),
                  session_id,
                  session_number,
                  ...((event.params.engagement_time_msec || engagement_time_msec) && {
                    engagement_time_msec: String(event.params.engagement_time_msec || engagement_time_msec),
                  }),

                  // UTM
                  ...utm_params,
                },
              }),
            })),
          },
        })
      } catch {}
    }
  }
}

const gaTracker = new GaTracker()
export default gaTracker

export function gaSetUserID (value?: number) {
  gaTracker.user_id = value
}

export function gaSetUserProperties (value: UserProperties) {
  gaTracker.user_properties = {
    ...gaTracker.user_properties,
    ...value,
  }
}

export function startNewEngagement () {
  gaTracker.startTime = new Date().getTime()
}

export async function gaLogin (method: string) {
  await gaTracker.send([
    {
      name: 'td_user_login',
      params: {
        method,
        login_location: document?.title,
      },
    },
  ])
}

export async function gaSignUp (method: string) {
  await gaTracker.send([
    {
      name: 'td_user_signup',
      params: {
        method,
        sign_up_location: document?.title,
      },
    },
  ])
}

export async function gaPurchase (data: EcommercePurchaseData) {
  await gaTracker.send([
    {
      name: 'purchase',
      params: data,
    },
  ])
}

export async function gaHome () {
  await gaTracker.send([
    {
      name: 'td_home',
      params: {
        target_detail: '點擊logo',
        target_location: document?.title,
      },
    },
  ])
}
