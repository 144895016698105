import { onErrorCaptured, onMounted, onUnmounted, ref, watch } from '@nuxtjs/composition-api'
import { useEventListener } from '@vueuse/core'

import { getActivator } from '@/modules/omnichat-activator'

import ggoLogger from '@/modules/ggo-logger'
import { ErrorType, LogName, Severity } from '@/interfaces/Logger'
import { isObject, isString } from 'typechecker'
import useGaTracker from '@/composables/use-ga-tracker'
import useI18n from '@/composables/use-i18n'
import useBreakpoint from '@/composables/use-breakpoint'
import gbClient from '@/modules/growthbook/gb-client'

declare global {
  interface Window { Aftee: any; TPDirect: any; }
}

export default function () {
  const omnichatActivator = ref<HTMLElement>()

  /** 監聽 Omnichat 按鈕的點擊事件 */
  const onClickOmnichatActivator = () => {}
  onMounted(async () => {
    omnichatActivator.value = await getActivator()
  })
  useEventListener(omnichatActivator, 'click', onClickOmnichatActivator)

  /** 根據 locale 更改 __layout 的 class */
  const i18n = useI18n()
  onMounted(() => {
    watch(() => i18n.locale, (val, oldVal) => {
      const __layoutElem = document.getElementById('__layout')
      if (__layoutElem) {
        __layoutElem.classList.add(`font-${val}`)
        oldVal && __layoutElem.classList.remove(`font-${oldVal}`)
      }
    }, { immediate: true })
  })

  /** 捕捉全局點擊事件 */
  const onClick = (event: MouseEvent) => {
    const target = event.target as HTMLElement
    let parent: HTMLElement | null = target
    while (parent && !parent.dataset.bid) {
      parent = parent.parentElement
    }
    if (parent && parent.dataset.bid) {
      ggoLogger.info(LogName.CLICK, {
        bid: parent.dataset.bid!,
      })
    }
  }
  onMounted(() => {
    document.addEventListener('click', onClick, true)
  })
  onUnmounted(() => {
    document.removeEventListener('click', onClick, true)
  })

  /** 捕捉全局錯誤訊息 */
  onErrorCaptured((error: Error, _instance: Vue, info: any) => {
    let data: Record<string, any> = {}
    if (info) {
      // 若 info 為 string, 目前看起來是回報在哪個 lifecycle hook
      if (isString(info)) {
        data.lifecycle = info
      } else if (isObject(info)) {
        data = {
          ...data,
          ...info,
        }
      }
    }

    ggoLogger.error({
      severity: Severity.Critical,
      error_type: ErrorType.ERROR_BOUNDARY,
      status_code: 404,
      message: error.message,
      error_stack: error.stack?.substring(0, 255),
      data,
    })
  })

  /** Tracker - GA */
  useGaTracker()

  /** Growthbook 更新 attribute & feature flags */
  const { match } = useBreakpoint()
  onMounted(() => {
    const isMobile = !match('lg')
    gbClient.updateContext({
      isMobile,
    })
  })
}
