enum PromotionID {
  // for gogoout
  COUPON = 1,
  PROMOTION_CODE = 2,
  GO_POINTS = 3,
  // for hertz
  PC = 4,
  CDP = 5,
}

export default PromotionID
