import { render, staticRenderFns } from "./auth-payment.vue?vue&type=template&id=32fa2c58&lang=pug&"
import script from "./auth-payment.vue?vue&type=script&lang=ts&"
export * from "./auth-payment.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TheNavbar: require('/home/runner/work/gg_code/gg_code/nuxt/components/the/TheNavbar.vue').default,GAuthTimerView: require('/home/runner/work/gg_code/gg_code/nuxt/components/GAuthTimerView.vue').default,GLoading: require('/home/runner/work/gg_code/gg_code/nuxt/components/GLoading.vue').default,AccountLoginOrSignup: require('/home/runner/work/gg_code/gg_code/nuxt/components/account/AccountLoginOrSignup.vue').default,TheFooter: require('/home/runner/work/gg_code/gg_code/nuxt/components/the/TheFooter.vue').default,CookieInfo: require('/home/runner/work/gg_code/gg_code/nuxt/components/CookieInfo.vue').default})
