type UseNuxtImageOptions = {
  width?: number;
  height?: number;
  quality?: number;
  placeholder?: boolean;
  sizes?: string;
  imgAttrs?: Record<string, any>;
  format?: string;
  src?: string;
  sources?: Array<string>,
  preload?: boolean;
  loading?: 'lazy' | 'eager'
}

const PLACEHOLDER_QUALITY = 30

/**
 * 該hook為使用 Nuxt-img 套件需要的 middleware
 * 目前先以本地做webp的轉檔, 因此這個hook只是個假過濾層
 */
export default function (options: UseNuxtImageOptions) {
  // Mockup: useContext().app.$img
  const $img = (src: string, _props: any) => {
    if (!src.startsWith('http')) {
      return require(`@/assets/img/${src}`)
    } else return src
  }

  const width = options.width
  const height = options.height
  const quality = options.quality ?? 100
  let placeholder: boolean | number | Array<number> | string = !!options.placeholder
  const sizes = options.sizes

  if (width && height && options.quality) placeholder = [width, height, PLACEHOLDER_QUALITY]
  else placeholder = [PLACEHOLDER_QUALITY]

  const imgAttrs = {
    ...options.imgAttrs,
  }

  return {
    $img,
    props: {
      width,
      height,
      quality,
      placeholder,
      sizes,
      format: options.format ?? 'webp',
      imgAttrs,
      src: options.src,
      sources: options.sources,
      preload: options.preload,
      loading: options.loading,
    },
  }
}
