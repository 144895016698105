import { render, staticRenderFns } from "./OrderChangeInvoice.vue?vue&type=template&id=0f04e3dc&scoped=true&lang=pug&"
import script from "./OrderChangeInvoice.vue?vue&type=script&lang=ts&"
export * from "./OrderChangeInvoice.vue?vue&type=script&lang=ts&"
import style0 from "./OrderChangeInvoice.vue?vue&type=style&index=0&id=0f04e3dc&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f04e3dc",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GFieldInvoice: require('/home/runner/work/gg_code/gg_code/nuxt/components/GFieldInvoice.vue').default,GBtn: require('/home/runner/work/gg_code/gg_code/nuxt/components/GBtn.vue').default,GCard: require('/home/runner/work/gg_code/gg_code/nuxt/components/GCard.vue').default})
