/** 列舉付款方式 */
enum PaymentMethod {
  /** 信用卡 */
  CreditCard = 1,
  /** 中租零卡 App 線上付款 */
  ZeroCard = 2,
  /** LINE Pay */
  LinePay = 3,
  /** Aftee */
  Aftee = 4,
  /** TapPay */
  DebitCard = 5,
}

export default PaymentMethod
