
























































































import { computed, defineComponent, useContext, ref } from '@nuxtjs/composition-api'
import { Options as PopperOptions } from '@popperjs/core'

import useBreakpoint from '@/composables/use-breakpoint'
import useShowable from '@/composables/use-showable'
import useIsMounted from '@/composables/use-is-mounted'

import useNuxtImage from '@/composables/use-nuxt-image'
import alert from '@/modules/alert'
import useI18n from '@/composables/use-i18n'

export default defineComponent({
  props: {
    fixed: {
      type: Boolean,
      default: false,
    },
  },
  setup () {
    const i18n = useI18n()
    const { app: { store, localePath }, redirect } = useContext()
    const { isShow, show, hide } = useShowable()
    const menuOptions: PopperOptions = {
      placement: 'bottom-start',
      strategy: 'absolute',
      modifiers: [{ name: 'offset', options: { offset: [0, 5] } }],
    }

    const breakpoint = 'md'
    const { match } = useBreakpoint()
    const isMobile = computed(() => !match(breakpoint))

    const isMounted = useIsMounted()

    const showModal = ref<Boolean>(false)

    const openLoginModal = () => {
      showModal.value = true
    }

    const closeModal = () => {
      showModal.value = false
    }

    const user = computed(() => store?.state.user.user)
    const isFetching = computed((): boolean => store?.state.user.isFetching)
    const signOut = () => {
      store?.dispatch('user/signOut')
      alert(i18n.t('notice.logout.success'))
      redirect(localePath('/'))
    }

    const { props: logoProps } = useNuxtImage({
      placeholder: true,
      width: 128,
      imgAttrs: {
        class: 'h-8 min-w-32',
        alt: 'gogoout logo',
      },
      src: 'logo/logo.png',
      sources: ['logo/logo.webp', 'logo/logo.png'],
    })

    return {
      isShow,
      show,
      hide,
      menuOptions,

      breakpoint,
      isMobile,

      isMounted,

      showModal,
      openLoginModal,
      closeModal,

      user,
      isFetching,
      signOut,

      logoProps,
    }
  },
})
