






import { computed, defineComponent, PropType } from '@vue/composition-api'
import { lookup } from 'mrmime'

export default defineComponent({
  props: {
    loading: {
      type: String,
    },
    /** 原圖 */
    src: {
      type: String,
    },
    /** picture裡的圖片sources */
    sources: {
      type: Array as PropType<Array<string>>,
      default: () => [],
    },
    imgAttrs: {
      type: Object,
    },
  },
  setup (props) {
    const getImgSrc = (src?: string) => {
      if (!src) return src

      if (!src.startsWith('http')) {
        return require(`@/assets/img/${src}`)
      } else return src
    }

    const _src = computed(() => {
      return getImgSrc(props.src)
    })

    const _sources = computed(() => {
      return props.sources.map(source => ({
        bind: {
          srcset: getImgSrc(source),
          type: lookup(source),
        },
      }))
    })

    const _bind = computed(() => ({
      ...(props.imgAttrs ?? {}),
      loading: props.loading,
    }))

    return {
      _src,
      _bind,
      _sources,
    }
  },
})
