








import { defineComponent } from '@vue/composition-api'
import useNuxtImage from '@/composables/use-nuxt-image'

export default defineComponent({
  props: {
    size: {
      type: String,
      default: 'default',
    },
  },
  setup () {
    const { props: imageProps } = useNuxtImage({
      placeholder: true,
      width: 20,
      height: 20,
      imgAttrs: {
        alt: 'Google Map Icon',
        class: 'w-full h-full',
      },
    })

    return {
      imageProps,
    }
  },
})
