/** 使用者行為追蹤相關參數 Key */
enum GgoTrackKey {
  DEVICE_ID_STORE_KEY = 'ggo_device_id',
  SESSION_ID_STORE_KEY = 'ggo_session_id',
  REFERENCE_CODE = 'referenceCode',
  UTM = 'ggo_utm',
  TRACKING_CODE = 'trackingCode',
}

export default GgoTrackKey
