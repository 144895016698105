import { render, staticRenderFns } from "./TheFooter.vue?vue&type=template&id=c15ee9a6&scoped=true&lang=pug&"
import script from "./TheFooter.vue?vue&type=script&lang=ts&"
export * from "./TheFooter.vue?vue&type=script&lang=ts&"
import style0 from "./TheFooter.vue?vue&type=style&index=0&id=c15ee9a6&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c15ee9a6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NuxtPicture: require('/home/runner/work/gg_code/gg_code/nuxt/components/NuxtPicture.vue').default,TheFooterSocialMediaList: require('/home/runner/work/gg_code/gg_code/nuxt/components/the/TheFooterSocialMediaList.vue').default})
