import { render, staticRenderFns } from "./OrderTableRowAlive.vue?vue&type=template&id=59be518d&lang=pug&"
import script from "./OrderTableRowAlive.vue?vue&type=script&lang=ts&"
export * from "./OrderTableRowAlive.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OrderTableCellOrderNum: require('/home/runner/work/gg_code/gg_code/nuxt/components/order/table/cell/OrderTableCellOrderNum.vue').default,OrderTableCell: require('/home/runner/work/gg_code/gg_code/nuxt/components/order/table/cell/OrderTableCell.vue').default,OrderTableCellText: require('/home/runner/work/gg_code/gg_code/nuxt/components/order/table/cell/OrderTableCellText.vue').default,OrderTableCellLink: require('/home/runner/work/gg_code/gg_code/nuxt/components/order/table/cell/OrderTableCellLink.vue').default,OrderTableCellInsurances: require('/home/runner/work/gg_code/gg_code/nuxt/components/order/table/cell/OrderTableCellInsurances.vue').default,OrderTableCellAction: require('/home/runner/work/gg_code/gg_code/nuxt/components/order/table/cell/OrderTableCellAction.vue').default,GLoading: require('/home/runner/work/gg_code/gg_code/nuxt/components/GLoading.vue').default})
