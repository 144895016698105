import { onBeforeUnmount, onMounted, useRoute, watch } from '@nuxtjs/composition-api'
import { gaHome, startNewEngagement } from '@/modules/tracker/ga'

export default function () {
  const route = useRoute()

  onMounted(() => {
    watch(route, () => {
      if (!process.server) {
        startNewEngagement()
      }
    }, { immediate: true })
  })

  onMounted(() => {
    document.getElementById('td_home')?.addEventListener('click', gaHome)
  })

  onBeforeUnmount(() => {
    document.getElementById('td_home')?.removeEventListener('click', gaHome)
  })
}
