import Vue from 'vue'
import { wrapFunctional } from './utils'

const components = {
  AddPaymentCard: () => import('../../components/AddPaymentCard.vue' /* webpackChunkName: "components/add-payment-card" */).then(c => wrapFunctional(c.default || c)),
  AdsCarousel: () => import('../../components/AdsCarousel.vue' /* webpackChunkName: "components/ads-carousel" */).then(c => wrapFunctional(c.default || c)),
  BottomFixedFilterActivator: () => import('../../components/BottomFixedFilterActivator.vue' /* webpackChunkName: "components/bottom-fixed-filter-activator" */).then(c => wrapFunctional(c.default || c)),
  BottomFixedModeSwitcher: () => import('../../components/BottomFixedModeSwitcher.vue' /* webpackChunkName: "components/bottom-fixed-mode-switcher" */).then(c => wrapFunctional(c.default || c)),
  CarDescriptionList: () => import('../../components/CarDescriptionList.vue' /* webpackChunkName: "components/car-description-list" */).then(c => wrapFunctional(c.default || c)),
  CarImageCarousel: () => import('../../components/CarImageCarousel.vue' /* webpackChunkName: "components/car-image-carousel" */).then(c => wrapFunctional(c.default || c)),
  CardCar: () => import('../../components/CardCar.vue' /* webpackChunkName: "components/card-car" */).then(c => wrapFunctional(c.default || c)),
  CardReview: () => import('../../components/CardReview.vue' /* webpackChunkName: "components/card-review" */).then(c => wrapFunctional(c.default || c)),
  Cleave: () => import('../../components/Cleave.vue' /* webpackChunkName: "components/cleave" */).then(c => wrapFunctional(c.default || c)),
  CookieInfo: () => import('../../components/CookieInfo.vue' /* webpackChunkName: "components/cookie-info" */).then(c => wrapFunctional(c.default || c)),
  CreditCard: () => import('../../components/CreditCard.vue' /* webpackChunkName: "components/credit-card" */).then(c => wrapFunctional(c.default || c)),
  GAccordion: () => import('../../components/GAccordion.vue' /* webpackChunkName: "components/g-accordion" */).then(c => wrapFunctional(c.default || c)),
  GAlert: () => import('../../components/GAlert.vue' /* webpackChunkName: "components/g-alert" */).then(c => wrapFunctional(c.default || c)),
  GAuthTimerView: () => import('../../components/GAuthTimerView.vue' /* webpackChunkName: "components/g-auth-timer-view" */).then(c => wrapFunctional(c.default || c)),
  GBanner: () => import('../../components/GBanner.vue' /* webpackChunkName: "components/g-banner" */).then(c => wrapFunctional(c.default || c)),
  GBottomDrawer: () => import('../../components/GBottomDrawer.vue' /* webpackChunkName: "components/g-bottom-drawer" */).then(c => wrapFunctional(c.default || c)),
  GBreadcrumb: () => import('../../components/GBreadcrumb.vue' /* webpackChunkName: "components/g-breadcrumb" */).then(c => wrapFunctional(c.default || c)),
  GBtn: () => import('../../components/GBtn.vue' /* webpackChunkName: "components/g-btn" */).then(c => wrapFunctional(c.default || c)),
  GBtnIcon: () => import('../../components/GBtnIcon.vue' /* webpackChunkName: "components/g-btn-icon" */).then(c => wrapFunctional(c.default || c)),
  GCard: () => import('../../components/GCard.vue' /* webpackChunkName: "components/g-card" */).then(c => wrapFunctional(c.default || c)),
  GCarousel: () => import('../../components/GCarousel.vue' /* webpackChunkName: "components/g-carousel" */).then(c => wrapFunctional(c.default || c)),
  GCheckbox: () => import('../../components/GCheckbox.vue' /* webpackChunkName: "components/g-checkbox" */).then(c => wrapFunctional(c.default || c)),
  GChippyCheckbox: () => import('../../components/GChippyCheckbox.vue' /* webpackChunkName: "components/g-chippy-checkbox" */).then(c => wrapFunctional(c.default || c)),
  GDialog: () => import('../../components/GDialog.vue' /* webpackChunkName: "components/g-dialog" */).then(c => wrapFunctional(c.default || c)),
  GDialogBody: () => import('../../components/GDialogBody.vue' /* webpackChunkName: "components/g-dialog-body" */).then(c => wrapFunctional(c.default || c)),
  GDialogContainer: () => import('../../components/GDialogContainer.vue' /* webpackChunkName: "components/g-dialog-container" */).then(c => wrapFunctional(c.default || c)),
  GDialogFooter: () => import('../../components/GDialogFooter.vue' /* webpackChunkName: "components/g-dialog-footer" */).then(c => wrapFunctional(c.default || c)),
  GDialogHeader: () => import('../../components/GDialogHeader.vue' /* webpackChunkName: "components/g-dialog-header" */).then(c => wrapFunctional(c.default || c)),
  GDialogOrMenu: () => import('../../components/GDialogOrMenu.vue' /* webpackChunkName: "components/g-dialog-or-menu" */).then(c => wrapFunctional(c.default || c)),
  GError: () => import('../../components/GError.vue' /* webpackChunkName: "components/g-error" */).then(c => wrapFunctional(c.default || c)),
  GFeatureFlagComp: () => import('../../components/GFeatureFlagComp.vue' /* webpackChunkName: "components/g-feature-flag-comp" */).then(c => wrapFunctional(c.default || c)),
  GField: () => import('../../components/GField.vue' /* webpackChunkName: "components/g-field" */).then(c => wrapFunctional(c.default || c)),
  GFieldBirthday: () => import('../../components/GFieldBirthday.vue' /* webpackChunkName: "components/g-field-birthday" */).then(c => wrapFunctional(c.default || c)),
  GFieldCountryCode: () => import('../../components/GFieldCountryCode.vue' /* webpackChunkName: "components/g-field-country-code" */).then(c => wrapFunctional(c.default || c)),
  GFieldCreditCard: () => import('../../components/GFieldCreditCard.vue' /* webpackChunkName: "components/g-field-credit-card" */).then(c => wrapFunctional(c.default || c)),
  GFieldDate: () => import('../../components/GFieldDate.vue' /* webpackChunkName: "components/g-field-date" */).then(c => wrapFunctional(c.default || c)),
  GFieldDateHour: () => import('../../components/GFieldDateHour.vue' /* webpackChunkName: "components/g-field-date-hour" */).then(c => wrapFunctional(c.default || c)),
  GFieldDeleteCard: () => import('../../components/GFieldDeleteCard.vue' /* webpackChunkName: "components/g-field-delete-card" */).then(c => wrapFunctional(c.default || c)),
  GFieldHint: () => import('../../components/GFieldHint.vue' /* webpackChunkName: "components/g-field-hint" */).then(c => wrapFunctional(c.default || c)),
  GFieldHour: () => import('../../components/GFieldHour.vue' /* webpackChunkName: "components/g-field-hour" */).then(c => wrapFunctional(c.default || c)),
  GFieldInvoice: () => import('../../components/GFieldInvoice.vue' /* webpackChunkName: "components/g-field-invoice" */).then(c => wrapFunctional(c.default || c)),
  GFieldMobile: () => import('../../components/GFieldMobile.vue' /* webpackChunkName: "components/g-field-mobile" */).then(c => wrapFunctional(c.default || c)),
  GFieldOptionalItem: () => import('../../components/GFieldOptionalItem.vue' /* webpackChunkName: "components/g-field-optional-item" */).then(c => wrapFunctional(c.default || c)),
  GFieldPaymentCleave: () => import('../../components/GFieldPaymentCleave.vue' /* webpackChunkName: "components/g-field-payment-cleave" */).then(c => wrapFunctional(c.default || c)),
  GFieldPaymentMethod: () => import('../../components/GFieldPaymentMethod.vue' /* webpackChunkName: "components/g-field-payment-method" */).then(c => wrapFunctional(c.default || c)),
  GFieldReferralAd: () => import('../../components/GFieldReferralAd.vue' /* webpackChunkName: "components/g-field-referral-ad" */).then(c => wrapFunctional(c.default || c)),
  GFieldSetCard: () => import('../../components/GFieldSetCard.vue' /* webpackChunkName: "components/g-field-set-card" */).then(c => wrapFunctional(c.default || c)),
  GFieldStoreService: () => import('../../components/GFieldStoreService.vue' /* webpackChunkName: "components/g-field-store-service" */).then(c => wrapFunctional(c.default || c)),
  GGenCarImageCarousel: () => import('../../components/GGenCarImageCarousel.vue' /* webpackChunkName: "components/g-gen-car-image-carousel" */).then(c => wrapFunctional(c.default || c)),
  GInvite: () => import('../../components/GInvite.vue' /* webpackChunkName: "components/g-invite" */).then(c => wrapFunctional(c.default || c)),
  GLabel: () => import('../../components/GLabel.vue' /* webpackChunkName: "components/g-label" */).then(c => wrapFunctional(c.default || c)),
  GLazyVideo: () => import('../../components/GLazyVideo.vue' /* webpackChunkName: "components/g-lazy-video" */).then(c => wrapFunctional(c.default || c)),
  GLink: () => import('../../components/GLink.vue' /* webpackChunkName: "components/g-link" */).then(c => wrapFunctional(c.default || c)),
  GList: () => import('../../components/GList.vue' /* webpackChunkName: "components/g-list" */).then(c => wrapFunctional(c.default || c)),
  GListItem: () => import('../../components/GListItem.vue' /* webpackChunkName: "components/g-list-item" */).then(c => wrapFunctional(c.default || c)),
  GLoading: () => import('../../components/GLoading.vue' /* webpackChunkName: "components/g-loading" */).then(c => wrapFunctional(c.default || c)),
  GMenu: () => import('../../components/GMenu.vue' /* webpackChunkName: "components/g-menu" */).then(c => wrapFunctional(c.default || c)),
  GModal: () => import('../../components/GModal.vue' /* webpackChunkName: "components/g-modal" */).then(c => wrapFunctional(c.default || c)),
  GPageNotFound: () => import('../../components/GPageNotFound.vue' /* webpackChunkName: "components/g-page-not-found" */).then(c => wrapFunctional(c.default || c)),
  GPayResult: () => import('../../components/GPayResult.vue' /* webpackChunkName: "components/g-pay-result" */).then(c => wrapFunctional(c.default || c)),
  GPlaceholderNuxtImage: () => import('../../components/GPlaceholderNuxtImage.vue' /* webpackChunkName: "components/g-placeholder-nuxt-image" */).then(c => wrapFunctional(c.default || c)),
  GRadio: () => import('../../components/GRadio.vue' /* webpackChunkName: "components/g-radio" */).then(c => wrapFunctional(c.default || c)),
  GReferenceModal: () => import('../../components/GReferenceModal.vue' /* webpackChunkName: "components/g-reference-modal" */).then(c => wrapFunctional(c.default || c)),
  GRefreshSearchModal: () => import('../../components/GRefreshSearchModal.vue' /* webpackChunkName: "components/g-refresh-search-modal" */).then(c => wrapFunctional(c.default || c)),
  GScore: () => import('../../components/GScore.vue' /* webpackChunkName: "components/g-score" */).then(c => wrapFunctional(c.default || c)),
  GSelect: () => import('../../components/GSelect.vue' /* webpackChunkName: "components/g-select" */).then(c => wrapFunctional(c.default || c)),
  GSelectMenu: () => import('../../components/GSelectMenu.vue' /* webpackChunkName: "components/g-select-menu" */).then(c => wrapFunctional(c.default || c)),
  GSidePanel: () => import('../../components/GSidePanel.vue' /* webpackChunkName: "components/g-side-panel" */).then(c => wrapFunctional(c.default || c)),
  GSignupBtn: () => import('../../components/GSignupBtn.vue' /* webpackChunkName: "components/g-signup-btn" */).then(c => wrapFunctional(c.default || c)),
  GSwitch: () => import('../../components/GSwitch.vue' /* webpackChunkName: "components/g-switch" */).then(c => wrapFunctional(c.default || c)),
  GTooltip: () => import('../../components/GTooltip.vue' /* webpackChunkName: "components/g-tooltip" */).then(c => wrapFunctional(c.default || c)),
  GView: () => import('../../components/GView.vue' /* webpackChunkName: "components/g-view" */).then(c => wrapFunctional(c.default || c)),
  LanguageSwitcher: () => import('../../components/LanguageSwitcher.vue' /* webpackChunkName: "components/language-switcher" */).then(c => wrapFunctional(c.default || c)),
  LinkConfirmPreviousPage: () => import('../../components/LinkConfirmPreviousPage.vue' /* webpackChunkName: "components/link-confirm-previous-page" */).then(c => wrapFunctional(c.default || c)),
  LinkStoreReview: () => import('../../components/LinkStoreReview.vue' /* webpackChunkName: "components/link-store-review" */).then(c => wrapFunctional(c.default || c)),
  ListCar: () => import('../../components/ListCar.vue' /* webpackChunkName: "components/list-car" */).then(c => wrapFunctional(c.default || c)),
  Locations: () => import('../../components/Locations.vue' /* webpackChunkName: "components/locations" */).then(c => wrapFunctional(c.default || c)),
  Logo: () => import('../../components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c)),
  NuxtPicture: () => import('../../components/NuxtPicture.vue' /* webpackChunkName: "components/nuxt-picture" */).then(c => wrapFunctional(c.default || c)),
  OrderDetail: () => import('../../components/OrderDetail.vue' /* webpackChunkName: "components/order-detail" */).then(c => wrapFunctional(c.default || c)),
  PaymentCard: () => import('../../components/PaymentCard.vue' /* webpackChunkName: "components/payment-card" */).then(c => wrapFunctional(c.default || c)),
  ServiceItemModal: () => import('../../components/ServiceItemModal.vue' /* webpackChunkName: "components/service-item-modal" */).then(c => wrapFunctional(c.default || c)),
  SliderPriceRange: () => import('../../components/SliderPriceRange.vue' /* webpackChunkName: "components/slider-price-range" */).then(c => wrapFunctional(c.default || c)),
  StateMessage: () => import('../../components/StateMessage.vue' /* webpackChunkName: "components/state-message" */).then(c => wrapFunctional(c.default || c)),
  StoreMap: () => import('../../components/StoreMap.vue' /* webpackChunkName: "components/store-map" */).then(c => wrapFunctional(c.default || c)),
  HertzCardCar: () => import('../../components/Hertz/HertzCardCar.vue' /* webpackChunkName: "components/hertz-card-car" */).then(c => wrapFunctional(c.default || c)),
  HertzLinkStoreReview: () => import('../../components/Hertz/HertzLinkStoreReview.vue' /* webpackChunkName: "components/hertz-link-store-review" */).then(c => wrapFunctional(c.default || c)),
  HertzListCar: () => import('../../components/Hertz/HertzListCar.vue' /* webpackChunkName: "components/hertz-list-car" */).then(c => wrapFunctional(c.default || c)),
  AboutPageTitle: () => import('../../components/about/AboutPageTitle.vue' /* webpackChunkName: "components/about-page-title" */).then(c => wrapFunctional(c.default || c)),
  AccountCheckInformation: () => import('../../components/account/AccountCheckInformation.vue' /* webpackChunkName: "components/account-check-information" */).then(c => wrapFunctional(c.default || c)),
  AccountLoginOrSignup: () => import('../../components/account/AccountLoginOrSignup.vue' /* webpackChunkName: "components/account-login-or-signup" */).then(c => wrapFunctional(c.default || c)),
  AccountResetPassword: () => import('../../components/account/AccountResetPassword.vue' /* webpackChunkName: "components/account-reset-password" */).then(c => wrapFunctional(c.default || c)),
  CollectionIndex: () => import('../../components/collection/CollectionIndex.vue' /* webpackChunkName: "components/collection-index" */).then(c => wrapFunctional(c.default || c)),
  CollectionIndexTaiwan: () => import('../../components/collection/CollectionIndexTaiwan.vue' /* webpackChunkName: "components/collection-index-taiwan" */).then(c => wrapFunctional(c.default || c)),
  CollectionSection: () => import('../../components/collection/CollectionSection.vue' /* webpackChunkName: "components/collection-section" */).then(c => wrapFunctional(c.default || c)),
  CommentList: () => import('../../components/comment/CommentList.vue' /* webpackChunkName: "components/comment-list" */).then(c => wrapFunctional(c.default || c)),
  CommentListItem: () => import('../../components/comment/CommentListItem.vue' /* webpackChunkName: "components/comment-list-item" */).then(c => wrapFunctional(c.default || c)),
  ConfirmFlightDetail: () => import('../../components/confirm/ConfirmFlightDetail.vue' /* webpackChunkName: "components/confirm-flight-detail" */).then(c => wrapFunctional(c.default || c)),
  ConfirmPromotion: () => import('../../components/confirm/ConfirmPromotion.vue' /* webpackChunkName: "components/confirm-promotion" */).then(c => wrapFunctional(c.default || c)),
  ConfirmSectionCarDetail: () => import('../../components/confirm/ConfirmSectionCarDetail.vue' /* webpackChunkName: "components/confirm-section-car-detail" */).then(c => wrapFunctional(c.default || c)),
  CorporateMemberFilePicker: () => import('../../components/corporate-member/CorporateMemberFilePicker.vue' /* webpackChunkName: "components/corporate-member-file-picker" */).then(c => wrapFunctional(c.default || c)),
  CorporateMemberForm: () => import('../../components/corporate-member/CorporateMemberForm.vue' /* webpackChunkName: "components/corporate-member-form" */).then(c => wrapFunctional(c.default || c)),
  CorporateMemberSection: () => import('../../components/corporate-member/CorporateMemberSection.vue' /* webpackChunkName: "components/corporate-member-section" */).then(c => wrapFunctional(c.default || c)),
  DatePicker: () => import('../../components/date-picker/DatePicker.vue' /* webpackChunkName: "components/date-picker" */).then(c => wrapFunctional(c.default || c)),
  DatePickerBody: () => import('../../components/date-picker/DatePickerBody.vue' /* webpackChunkName: "components/date-picker-body" */).then(c => wrapFunctional(c.default || c)),
  DatePickerCalendar: () => import('../../components/date-picker/DatePickerCalendar.vue' /* webpackChunkName: "components/date-picker-calendar" */).then(c => wrapFunctional(c.default || c)),
  DatePickerModule: () => import('../../components/date-picker/DatePickerModule.vue' /* webpackChunkName: "components/date-picker-module" */).then(c => wrapFunctional(c.default || c)),
  FilterBar: () => import('../../components/filter/FilterBar.vue' /* webpackChunkName: "components/filter-bar" */).then(c => wrapFunctional(c.default || c)),
  FilterBarAcceptForeigner: () => import('../../components/filter/FilterBarAcceptForeigner.vue' /* webpackChunkName: "components/filter-bar-accept-foreigner" */).then(c => wrapFunctional(c.default || c)),
  FilterBarAgeRange: () => import('../../components/filter/FilterBarAgeRange.vue' /* webpackChunkName: "components/filter-bar-age-range" */).then(c => wrapFunctional(c.default || c)),
  FilterBarBrand: () => import('../../components/filter/FilterBarBrand.vue' /* webpackChunkName: "components/filter-bar-brand" */).then(c => wrapFunctional(c.default || c)),
  FilterBarDialogActivator: () => import('../../components/filter/FilterBarDialogActivator.vue' /* webpackChunkName: "components/filter-bar-dialog-activator" */).then(c => wrapFunctional(c.default || c)),
  FilterBarFooter: () => import('../../components/filter/FilterBarFooter.vue' /* webpackChunkName: "components/filter-bar-footer" */).then(c => wrapFunctional(c.default || c)),
  FilterBarMore: () => import('../../components/filter/FilterBarMore.vue' /* webpackChunkName: "components/filter-bar-more" */).then(c => wrapFunctional(c.default || c)),
  FilterBarOneWayRental: () => import('../../components/filter/FilterBarOneWayRental.vue' /* webpackChunkName: "components/filter-bar-one-way-rental" */).then(c => wrapFunctional(c.default || c)),
  FilterBarPaymentMethod: () => import('../../components/filter/FilterBarPaymentMethod.vue' /* webpackChunkName: "components/filter-bar-payment-method" */).then(c => wrapFunctional(c.default || c)),
  FilterBarPriceRange: () => import('../../components/filter/FilterBarPriceRange.vue' /* webpackChunkName: "components/filter-bar-price-range" */).then(c => wrapFunctional(c.default || c)),
  FilterBarSeats: () => import('../../components/filter/FilterBarSeats.vue' /* webpackChunkName: "components/filter-bar-seats" */).then(c => wrapFunctional(c.default || c)),
  FilterBodyAcceptForeigner: () => import('../../components/filter/FilterBodyAcceptForeigner.vue' /* webpackChunkName: "components/filter-body-accept-foreigner" */).then(c => wrapFunctional(c.default || c)),
  FilterBodyAgeRange: () => import('../../components/filter/FilterBodyAgeRange.vue' /* webpackChunkName: "components/filter-body-age-range" */).then(c => wrapFunctional(c.default || c)),
  FilterBodyBrand: () => import('../../components/filter/FilterBodyBrand.vue' /* webpackChunkName: "components/filter-body-brand" */).then(c => wrapFunctional(c.default || c)),
  FilterBodyCarType: () => import('../../components/filter/FilterBodyCarType.vue' /* webpackChunkName: "components/filter-body-car-type" */).then(c => wrapFunctional(c.default || c)),
  FilterBodyEquipment: () => import('../../components/filter/FilterBodyEquipment.vue' /* webpackChunkName: "components/filter-body-equipment" */).then(c => wrapFunctional(c.default || c)),
  FilterBodyOneWayRental: () => import('../../components/filter/FilterBodyOneWayRental.vue' /* webpackChunkName: "components/filter-body-one-way-rental" */).then(c => wrapFunctional(c.default || c)),
  FilterBodyPaymentMethod: () => import('../../components/filter/FilterBodyPaymentMethod.vue' /* webpackChunkName: "components/filter-body-payment-method" */).then(c => wrapFunctional(c.default || c)),
  FilterBodyPriceRange: () => import('../../components/filter/FilterBodyPriceRange.vue' /* webpackChunkName: "components/filter-body-price-range" */).then(c => wrapFunctional(c.default || c)),
  FilterBodySeatRange: () => import('../../components/filter/FilterBodySeatRange.vue' /* webpackChunkName: "components/filter-body-seat-range" */).then(c => wrapFunctional(c.default || c)),
  FilterBodySortMethod: () => import('../../components/filter/FilterBodySortMethod.vue' /* webpackChunkName: "components/filter-body-sort-method" */).then(c => wrapFunctional(c.default || c)),
  FilterBodyStoreService: () => import('../../components/filter/FilterBodyStoreService.vue' /* webpackChunkName: "components/filter-body-store-service" */).then(c => wrapFunctional(c.default || c)),
  FilterDialog: () => import('../../components/filter/FilterDialog.vue' /* webpackChunkName: "components/filter-dialog" */).then(c => wrapFunctional(c.default || c)),
  FilterSortMethod: () => import('../../components/filter/FilterSortMethod.vue' /* webpackChunkName: "components/filter-sort-method" */).then(c => wrapFunctional(c.default || c)),
  GoogleMapMarker: () => import('../../components/google-map/GoogleMapMarker.vue' /* webpackChunkName: "components/google-map-marker" */).then(c => wrapFunctional(c.default || c)),
  GoogleMapViewer: () => import('../../components/google-map/GoogleMapViewer.vue' /* webpackChunkName: "components/google-map-viewer" */).then(c => wrapFunctional(c.default || c)),
  HintGoPointsHowTo: () => import('../../components/hint/HintGoPointsHowTo.vue' /* webpackChunkName: "components/hint-go-points-how-to" */).then(c => wrapFunctional(c.default || c)),
  HintGoPointsWhatIs: () => import('../../components/hint/HintGoPointsWhatIs.vue' /* webpackChunkName: "components/hint-go-points-what-is" */).then(c => wrapFunctional(c.default || c)),
  IconsGoogleMapIcon: () => import('../../components/icons/IconsGoogleMapIcon.vue' /* webpackChunkName: "components/icons-google-map-icon" */).then(c => wrapFunctional(c.default || c)),
  IndexBanner: () => import('../../components/index/IndexBanner.vue' /* webpackChunkName: "components/index-banner" */).then(c => wrapFunctional(c.default || c)),
  IndexHeader: () => import('../../components/index/IndexHeader.vue' /* webpackChunkName: "components/index-header" */).then(c => wrapFunctional(c.default || c)),
  IndexHeading: () => import('../../components/index/IndexHeading.vue' /* webpackChunkName: "components/index-heading" */).then(c => wrapFunctional(c.default || c)),
  IndexSectionCarousel: () => import('../../components/index/IndexSectionCarousel.vue' /* webpackChunkName: "components/index-section-carousel" */).then(c => wrapFunctional(c.default || c)),
  IndexSectionCollections: () => import('../../components/index/IndexSectionCollections.vue' /* webpackChunkName: "components/index-section-collections" */).then(c => wrapFunctional(c.default || c)),
  IndexSectionFeatures: () => import('../../components/index/IndexSectionFeatures.vue' /* webpackChunkName: "components/index-section-features" */).then(c => wrapFunctional(c.default || c)),
  IndexSectionIntroduction: () => import('../../components/index/IndexSectionIntroduction.vue' /* webpackChunkName: "components/index-section-introduction" */).then(c => wrapFunctional(c.default || c)),
  IndexSectionLocations: () => import('../../components/index/IndexSectionLocations.vue' /* webpackChunkName: "components/index-section-locations" */).then(c => wrapFunctional(c.default || c)),
  IndexSectionPromotion: () => import('../../components/index/IndexSectionPromotion.vue' /* webpackChunkName: "components/index-section-promotion" */).then(c => wrapFunctional(c.default || c)),
  IndexSectionReviews: () => import('../../components/index/IndexSectionReviews.vue' /* webpackChunkName: "components/index-section-reviews" */).then(c => wrapFunctional(c.default || c)),
  IndexSectionSteps: () => import('../../components/index/IndexSectionSteps.vue' /* webpackChunkName: "components/index-section-steps" */).then(c => wrapFunctional(c.default || c)),
  IndexSectionStepsMockup: () => import('../../components/index/IndexSectionStepsMockup.vue' /* webpackChunkName: "components/index-section-steps-mockup" */).then(c => wrapFunctional(c.default || c)),
  IndexWrapperSection: () => import('../../components/index/IndexWrapperSection.vue' /* webpackChunkName: "components/index-wrapper-section" */).then(c => wrapFunctional(c.default || c)),
  LocationCard: () => import('../../components/location/LocationCard.vue' /* webpackChunkName: "components/location-card" */).then(c => wrapFunctional(c.default || c)),
  LocationPicker: () => import('../../components/location-picker/LocationPicker.vue' /* webpackChunkName: "components/location-picker" */).then(c => wrapFunctional(c.default || c)),
  LocationPickerBody: () => import('../../components/location-picker/LocationPickerBody.vue' /* webpackChunkName: "components/location-picker-body" */).then(c => wrapFunctional(c.default || c)),
  LocationPickerPrediction: () => import('../../components/location-picker/LocationPickerPrediction.vue' /* webpackChunkName: "components/location-picker-prediction" */).then(c => wrapFunctional(c.default || c)),
  LocationPickerShortcut: () => import('../../components/location-picker/LocationPickerShortcut.vue' /* webpackChunkName: "components/location-picker-shortcut" */).then(c => wrapFunctional(c.default || c)),
  MemberContainer: () => import('../../components/member/MemberContainer.vue' /* webpackChunkName: "components/member-container" */).then(c => wrapFunctional(c.default || c)),
  OrderCard: () => import('../../components/order/OrderCard.vue' /* webpackChunkName: "components/order-card" */).then(c => wrapFunctional(c.default || c)),
  OrderCardRating: () => import('../../components/order/OrderCardRating.vue' /* webpackChunkName: "components/order-card-rating" */).then(c => wrapFunctional(c.default || c)),
  OrderChangeInvoice: () => import('../../components/order/OrderChangeInvoice.vue' /* webpackChunkName: "components/order-change-invoice" */).then(c => wrapFunctional(c.default || c)),
  OrderGetRefundDetail: () => import('../../components/order/OrderGetRefundDetail.vue' /* webpackChunkName: "components/order-get-refund-detail" */).then(c => wrapFunctional(c.default || c)),
  OrderGetReview: () => import('../../components/order/OrderGetReview.vue' /* webpackChunkName: "components/order-get-review" */).then(c => wrapFunctional(c.default || c)),
  OrderList: () => import('../../components/order/OrderList.vue' /* webpackChunkName: "components/order-list" */).then(c => wrapFunctional(c.default || c)),
  OrderListDetail: () => import('../../components/order/OrderListDetail.vue' /* webpackChunkName: "components/order-list-detail" */).then(c => wrapFunctional(c.default || c)),
  OrderNav: () => import('../../components/order/OrderNav.vue' /* webpackChunkName: "components/order-nav" */).then(c => wrapFunctional(c.default || c)),
  OrderRatingList: () => import('../../components/order/OrderRatingList.vue' /* webpackChunkName: "components/order-rating-list" */).then(c => wrapFunctional(c.default || c)),
  OrderResultPromotion: () => import('../../components/order-result/OrderResultPromotion.vue' /* webpackChunkName: "components/order-result-promotion" */).then(c => wrapFunctional(c.default || c)),
  OrderResultTableCell: () => import('../../components/order-result/OrderResultTableCell.vue' /* webpackChunkName: "components/order-result-table-cell" */).then(c => wrapFunctional(c.default || c)),
  ReviewPreview: () => import('../../components/review/ReviewPreview.vue' /* webpackChunkName: "components/review-preview" */).then(c => wrapFunctional(c.default || c)),
  SchemaOrganization: () => import('../../components/schema/SchemaOrganization.vue' /* webpackChunkName: "components/schema-organization" */).then(c => wrapFunctional(c.default || c)),
  SchemaStore: () => import('../../components/schema/SchemaStore.vue' /* webpackChunkName: "components/schema-store" */).then(c => wrapFunctional(c.default || c)),
  SearchAside: () => import('../../components/search/SearchAside.vue' /* webpackChunkName: "components/search-aside" */).then(c => wrapFunctional(c.default || c)),
  SearchMap: () => import('../../components/search/SearchMap.vue' /* webpackChunkName: "components/search-map" */).then(c => wrapFunctional(c.default || c)),
  SearchMapInfoWindow: () => import('../../components/search/SearchMapInfoWindow.vue' /* webpackChunkName: "components/search-map-info-window" */).then(c => wrapFunctional(c.default || c)),
  SearchMapMarker: () => import('../../components/search/SearchMapMarker.vue' /* webpackChunkName: "components/search-map-marker" */).then(c => wrapFunctional(c.default || c)),
  SearchMapStoreBody: () => import('../../components/search/SearchMapStoreBody.vue' /* webpackChunkName: "components/search-map-store-body" */).then(c => wrapFunctional(c.default || c)),
  SearchBar: () => import('../../components/search-bar/SearchBar.vue' /* webpackChunkName: "components/search-bar" */).then(c => wrapFunctional(c.default || c)),
  SearchBarActivator: () => import('../../components/search-bar/SearchBarActivator.vue' /* webpackChunkName: "components/search-bar-activator" */).then(c => wrapFunctional(c.default || c)),
  SearchBarFieldDate: () => import('../../components/search-bar/SearchBarFieldDate.vue' /* webpackChunkName: "components/search-bar-field-date" */).then(c => wrapFunctional(c.default || c)),
  SearchBarFieldDateHour: () => import('../../components/search-bar/SearchBarFieldDateHour.vue' /* webpackChunkName: "components/search-bar-field-date-hour" */).then(c => wrapFunctional(c.default || c)),
  SearchBarFieldHour: () => import('../../components/search-bar/SearchBarFieldHour.vue' /* webpackChunkName: "components/search-bar-field-hour" */).then(c => wrapFunctional(c.default || c)),
  SearchBarFieldLocation: () => import('../../components/search-bar/SearchBarFieldLocation.vue' /* webpackChunkName: "components/search-bar-field-location" */).then(c => wrapFunctional(c.default || c)),
  SearchBarFieldSeat: () => import('../../components/search-bar/SearchBarFieldSeat.vue' /* webpackChunkName: "components/search-bar-field-seat" */).then(c => wrapFunctional(c.default || c)),
  SearchBarFoldable: () => import('../../components/search-bar/SearchBarFoldable.vue' /* webpackChunkName: "components/search-bar-foldable" */).then(c => wrapFunctional(c.default || c)),
  SearchBarLabel: () => import('../../components/search-bar/SearchBarLabel.vue' /* webpackChunkName: "components/search-bar-label" */).then(c => wrapFunctional(c.default || c)),
  SearchBarTabs: () => import('../../components/search-bar/SearchBarTabs.vue' /* webpackChunkName: "components/search-bar-tabs" */).then(c => wrapFunctional(c.default || c)),
  SocialLineButton: () => import('../../components/social/SocialLineButton.vue' /* webpackChunkName: "components/social-line-button" */).then(c => wrapFunctional(c.default || c)),
  StoreCarDescriptionList: () => import('../../components/store/StoreCarDescriptionList.vue' /* webpackChunkName: "components/store-car-description-list" */).then(c => wrapFunctional(c.default || c)),
  StoreCardCar: () => import('../../components/store/StoreCardCar.vue' /* webpackChunkName: "components/store-card-car" */).then(c => wrapFunctional(c.default || c)),
  StoreListCar: () => import('../../components/store/StoreListCar.vue' /* webpackChunkName: "components/store-list-car" */).then(c => wrapFunctional(c.default || c)),
  StoreRating: () => import('../../components/store/StoreRating.vue' /* webpackChunkName: "components/store-rating" */).then(c => wrapFunctional(c.default || c)),
  StoreSearchCard: () => import('../../components/store/StoreSearchCard.vue' /* webpackChunkName: "components/store-search-card" */).then(c => wrapFunctional(c.default || c)),
  TheFooter: () => import('../../components/the/TheFooter.vue' /* webpackChunkName: "components/the-footer" */).then(c => wrapFunctional(c.default || c)),
  TheFooterSocialMediaList: () => import('../../components/the/TheFooterSocialMediaList.vue' /* webpackChunkName: "components/the-footer-social-media-list" */).then(c => wrapFunctional(c.default || c)),
  TheMobileNavbarBody: () => import('../../components/the/TheMobileNavbarBody.vue' /* webpackChunkName: "components/the-mobile-navbar-body" */).then(c => wrapFunctional(c.default || c)),
  TheNavbar: () => import('../../components/the/TheNavbar.vue' /* webpackChunkName: "components/the-navbar" */).then(c => wrapFunctional(c.default || c)),
  TheNavbarBody: () => import('../../components/the/TheNavbarBody.vue' /* webpackChunkName: "components/the-navbar-body" */).then(c => wrapFunctional(c.default || c)),
  HertzConfirmSectionCarDetail: () => import('../../components/Hertz/confirm/HertzConfirmSectionCarDetail.vue' /* webpackChunkName: "components/hertz-confirm-section-car-detail" */).then(c => wrapFunctional(c.default || c)),
  HertzFilterBar: () => import('../../components/Hertz/filter/HertzFilterBar.vue' /* webpackChunkName: "components/hertz-filter-bar" */).then(c => wrapFunctional(c.default || c)),
  HertzFilterDialog: () => import('../../components/Hertz/filter/HertzFilterDialog.vue' /* webpackChunkName: "components/hertz-filter-dialog" */).then(c => wrapFunctional(c.default || c)),
  AccountEdit: () => import('../../components/account/edit/AccountEdit.vue' /* webpackChunkName: "components/account-edit" */).then(c => wrapFunctional(c.default || c)),
  AccountEditBirthday: () => import('../../components/account/edit/AccountEditBirthday.vue' /* webpackChunkName: "components/account-edit-birthday" */).then(c => wrapFunctional(c.default || c)),
  AccountEditCredit: () => import('../../components/account/edit/AccountEditCredit.vue' /* webpackChunkName: "components/account-edit-credit" */).then(c => wrapFunctional(c.default || c)),
  AccountEditEmail: () => import('../../components/account/edit/AccountEditEmail.vue' /* webpackChunkName: "components/account-edit-email" */).then(c => wrapFunctional(c.default || c)),
  AccountEditIdentity: () => import('../../components/account/edit/AccountEditIdentity.vue' /* webpackChunkName: "components/account-edit-identity" */).then(c => wrapFunctional(c.default || c)),
  AccountEditName: () => import('../../components/account/edit/AccountEditName.vue' /* webpackChunkName: "components/account-edit-name" */).then(c => wrapFunctional(c.default || c)),
  AccountEditPassport: () => import('../../components/account/edit/AccountEditPassport.vue' /* webpackChunkName: "components/account-edit-passport" */).then(c => wrapFunctional(c.default || c)),
  AccountEditPhone: () => import('../../components/account/edit/AccountEditPhone.vue' /* webpackChunkName: "components/account-edit-phone" */).then(c => wrapFunctional(c.default || c)),
  AccountCheckEmail: () => import('../../components/account/status/AccountCheckEmail.vue' /* webpackChunkName: "components/account-check-email" */).then(c => wrapFunctional(c.default || c)),
  AccountLogin: () => import('../../components/account/status/AccountLogin.vue' /* webpackChunkName: "components/account-login" */).then(c => wrapFunctional(c.default || c)),
  AccountSignup: () => import('../../components/account/status/AccountSignup.vue' /* webpackChunkName: "components/account-signup" */).then(c => wrapFunctional(c.default || c)),
  OrderTableBody: () => import('../../components/order/table/OrderTableBody.vue' /* webpackChunkName: "components/order-table-body" */).then(c => wrapFunctional(c.default || c)),
  OrderTableHeader: () => import('../../components/order/table/OrderTableHeader.vue' /* webpackChunkName: "components/order-table-header" */).then(c => wrapFunctional(c.default || c)),
  OrderTableCell: () => import('../../components/order/table/cell/OrderTableCell.vue' /* webpackChunkName: "components/order-table-cell" */).then(c => wrapFunctional(c.default || c)),
  OrderTableCellAction: () => import('../../components/order/table/cell/OrderTableCellAction.vue' /* webpackChunkName: "components/order-table-cell-action" */).then(c => wrapFunctional(c.default || c)),
  OrderTableCellInsurances: () => import('../../components/order/table/cell/OrderTableCellInsurances.vue' /* webpackChunkName: "components/order-table-cell-insurances" */).then(c => wrapFunctional(c.default || c)),
  OrderTableCellLink: () => import('../../components/order/table/cell/OrderTableCellLink.vue' /* webpackChunkName: "components/order-table-cell-link" */).then(c => wrapFunctional(c.default || c)),
  OrderTableCellOrderNum: () => import('../../components/order/table/cell/OrderTableCellOrderNum.vue' /* webpackChunkName: "components/order-table-cell-order-num" */).then(c => wrapFunctional(c.default || c)),
  OrderTableCellText: () => import('../../components/order/table/cell/OrderTableCellText.vue' /* webpackChunkName: "components/order-table-cell-text" */).then(c => wrapFunctional(c.default || c)),
  OrderTableRowAlive: () => import('../../components/order/table/row/OrderTableRowAlive.vue' /* webpackChunkName: "components/order-table-row-alive" */).then(c => wrapFunctional(c.default || c)),
  OrderTableRowCanceled: () => import('../../components/order/table/row/OrderTableRowCanceled.vue' /* webpackChunkName: "components/order-table-row-canceled" */).then(c => wrapFunctional(c.default || c)),
  OrderTableRowComplete: () => import('../../components/order/table/row/OrderTableRowComplete.vue' /* webpackChunkName: "components/order-table-row-complete" */).then(c => wrapFunctional(c.default || c)),
  OrderTableRowDelivered: () => import('../../components/order/table/row/OrderTableRowDelivered.vue' /* webpackChunkName: "components/order-table-row-delivered" */).then(c => wrapFunctional(c.default || c)),
  OrderTableRowHold: () => import('../../components/order/table/row/OrderTableRowHold.vue' /* webpackChunkName: "components/order-table-row-hold" */).then(c => wrapFunctional(c.default || c)),
  OrderTableRowRating: () => import('../../components/order/table/row/OrderTableRowRating.vue' /* webpackChunkName: "components/order-table-row-rating" */).then(c => wrapFunctional(c.default || c))
}

for (const name in components) {
  Vue.component(name, components[name])
  Vue.component('Lazy' + name, components[name])
}
