import { render, staticRenderFns } from "./AccountLogin.vue?vue&type=template&id=abaf5e72&scoped=true&lang=pug&"
import script from "./AccountLogin.vue?vue&type=script&lang=ts&"
export * from "./AccountLogin.vue?vue&type=script&lang=ts&"
import style0 from "./AccountLogin.vue?vue&type=style&index=0&id=abaf5e72&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "abaf5e72",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GField: require('/home/runner/work/gg_code/gg_code/nuxt/components/GField.vue').default,GBtn: require('/home/runner/work/gg_code/gg_code/nuxt/components/GBtn.vue').default,GLink: require('/home/runner/work/gg_code/gg_code/nuxt/components/GLink.vue').default})
