




























































































































import { computed, defineComponent, onMounted, PropType, ref, watch } from '@nuxtjs/composition-api'

import { Invoice } from '@/interfaces/Invoice'
import { InvoiceType, InvoicePersonTo } from '@/enums/Invoice'

import useField from '@/composables/use-field'
import useI18n from '@/composables/use-i18n'

/**
 * Invoice type
 * 個人 (InvoiceType.PERSON)
 *   - 電子發票 (InvoicePersonTo.EMAIL)
 *
 *   - 共通性載具 ('driver')
 *       - 手機條碼 (InvoicePersonTo.MOBILE_CODE)
 *       - 自然人憑證 (InvoicePersonTo.EMAIL)
 *
 *   - 捐贈 (InvoicePersonTo.DONATE)
 *
 * 企業 (InvoiceType.COMPANY)
 */
type Person = InvoicePersonTo.EMAIL | 'driver' | InvoicePersonTo.DONATE
type Driver = InvoicePersonTo.MOBILE_CODE | InvoicePersonTo.NATURE

export default defineComponent({
  props: {
    value: {
      type: [Object, null] as PropType<Invoice | null>,
      default: null,
    },
  },
  setup (props, { emit }) {
    const i18n = useI18n()

    const proxyValue = computed({
      get: (): Invoice => {
        if (receiver.value === InvoiceType.PERSON) {
          if (person.value === InvoicePersonTo.EMAIL) {
            return {
              type: InvoiceType.PERSON,
              to: person.value,
              code: null,
            }
          } else if (person.value === 'driver') {
            if ([InvoicePersonTo.MOBILE_CODE, InvoicePersonTo.NATURE].includes(driver.value!)) {
              return {
                type: InvoiceType.PERSON,
                to: driver.value!,
                code: driverCode.value,
              }
            }
          } else if (person.value === InvoicePersonTo.DONATE) {
            if (donate.value) {
              return {
                type: InvoiceType.PERSON,
                to: person.value,
                code: donate.value,
              }
            } else {
              return {
                type: InvoiceType.PERSON,
                to: person.value,
                code: loveCode.value,
              }
            }
          }
        } else if (receiver.value === InvoiceType.COMPANY) {
          return {
            type: InvoiceType.COMPANY,
            to: InvoiceType.COMPANY,
            code: companyTaxId.value!,
            name: companyName.value!,
          }
        }
        return {
          type: InvoiceType.PERSON,
          to: InvoicePersonTo.EMAIL,
          code: null,
        }
      },
      set: (value: Invoice) => {
        if (!value) return

        const { to, code } = value
        receiver.value = getReceiverByTo(to)

        if (to === InvoicePersonTo.EMAIL) {
          person.value = to
        } else if (to === InvoicePersonTo.MOBILE_CODE) {
          person.value = 'driver'
          driver.value = to
          driverCode.value = code ?? null
        } else if (to === InvoicePersonTo.NATURE) {
          person.value = 'driver'
          driver.value = to
          driverCode.value = code ?? null
        } else if (to === InvoicePersonTo.DONATE) {
          person.value = to
          if (loveCodes.map(i => i.value).includes(String(code))) { // 若愛心碼有出現在選單內
            donate.value = code ?? null
          } else {
            donate.value = null
            loveCode.value = code ?? null
          }
        } else if (to === InvoiceType.COMPANY) {
          companyName.value = value.name ?? null
          companyTaxId.value = code ?? null
        }
      },
    })

    const isValid = computed(() => {
      return Object.values(proxyValue.value)
        .every(Boolean)
    })

    onMounted(() => {
      watch(proxyValue, value => emit('input', value))
      watch(() => props.value, (value) => {
        if (value) { proxyValue.value = value }
      }, { immediate: true })
    })

    const loveCodes = [
      {
        value: '1959',
        name: '1959 社團法人臺灣酒駕防制社會關懷協會(TADD)',
      },
    ]

    const receiver = ref<InvoiceType | null>(null)
    const person = ref<Person | null>(InvoicePersonTo.EMAIL)
    const driver = ref<Driver | null>(InvoicePersonTo.MOBILE_CODE)
    const driverPlaceholder = computed(() => {
      switch (driver.value) {
        case InvoicePersonTo.MOBILE_CODE:
          return i18n.t('field.invoice.person.driverCode.placeholder').toString()
        case InvoicePersonTo.NATURE:
          return i18n.t('field.invoice.person.driverCode.nature.placeholder').toString()
        default:
          return ''
      }
    })
    const driverCode = useField<string | null>(null, (v) => {
      switch (driver.value) {
        case InvoicePersonTo.MOBILE_CODE:
          return !v?.trim() ? i18n.t('field.invoice.person.driverCode.message.required').toString() : true
        case InvoicePersonTo.NATURE:
          return !v?.match(/^[A-Z]{2}\d{14}$/) ? i18n.t('field.invoice.person.driverCode.nature.message.required').toString() : true
        default:
          return ''
      }
    })

    const driverList = [
      { name: i18n.t('field.invoice.person.driver.option.mobileCode'), value: InvoicePersonTo.MOBILE_CODE },
      { name: i18n.t('field.invoice.person.driver.option.nature'), value: InvoicePersonTo.NATURE },
    ]

    const donate = ref<string | null>('1959')
    const loveCode = useField<string | null>(null, (v) => {
      if (!v) return String(i18n.t('field.invoice.person.donate.loveCode.message.required'))
      return true
    })
    const companyName = useField<string | null>(null, (v) => {
      if (!v) return String(i18n.t('field.invoice.company.companyName.message.required'))
      return true
    })
    const companyTaxId = useField<string | null>(null, (v) => {
      if (!v) return String(i18n.t('field.invoice.company.companyTaxId.message.required'))
      return true
    })

    // 觸發欄位檢查並顯示對應錯誤訊息
    const validate = () => {
      resetAllFields()
      if (receiver.value === InvoiceType.PERSON) {
        if (person.value === 'driver') {
          if ([InvoicePersonTo.MOBILE_CODE, InvoicePersonTo.NATURE].includes(driver.value!)) {
            return driverCode.validate()
          }
        } else if (person.value === InvoicePersonTo.DONATE) {
          if (!donate.value) {
            return loveCode.validate()
          }
        }
      } else if (receiver.value === InvoiceType.COMPANY) {
        const isCompanyTaxIdValid = companyTaxId.validate()
        const isCompanyNameValid = companyName.validate()
        return isCompanyTaxIdValid && isCompanyNameValid
      }
      return true
    }

    const resetAllFields = () => {
      [
        driverCode,
        loveCode,
        companyName,
        companyTaxId,
      ].forEach(i => i.resetField())
    }
    // 選擇了不同的項目時清空錯誤訊息
    watch([receiver, person, driver, donate], resetAllFields)

    const getReceiverByTo = (to: InvoicePersonTo | InvoiceType): InvoiceType => {
      if ([InvoicePersonTo.EMAIL, InvoicePersonTo.MOBILE_CODE, InvoicePersonTo.NATURE, InvoicePersonTo.DONATE].includes(to as InvoicePersonTo)) {
        return InvoiceType.PERSON
      } else if ([InvoiceType.COMPANY].includes(to as InvoiceType)) {
        return InvoiceType.COMPANY
      }
      return InvoiceType.PERSON
    }

    watch(receiver, value => emit('receiver-change', value))

    return {
      proxyValue,
      isValid,

      InvoiceType,
      InvoicePersonTo,

      loveCodes,

      receiver,
      person,
      driver,
      driverPlaceholder,
      driverCode,
      driverList,
      donate,
      loveCode,
      companyName,
      companyTaxId,

      validate,
      resetAllFields,
    }
  },
})
