import { render, staticRenderFns } from "./GAlert.vue?vue&type=template&id=cfde7834&scoped=true&lang=pug&"
import script from "./GAlert.vue?vue&type=script&lang=ts&"
export * from "./GAlert.vue?vue&type=script&lang=ts&"
import style0 from "./GAlert.vue?vue&type=style&index=0&id=cfde7834&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cfde7834",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GBtn: require('/home/runner/work/gg_code/gg_code/nuxt/components/GBtn.vue').default,GCard: require('/home/runner/work/gg_code/gg_code/nuxt/components/GCard.vue').default,GModal: require('/home/runner/work/gg_code/gg_code/nuxt/components/GModal.vue').default})
