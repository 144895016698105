



















import { defineComponent, onMounted } from '@nuxtjs/composition-api'
import Cookies from 'js-cookie'

import useShowable from '@/composables/use-showable'

export default defineComponent({
  setup () {
    const key = 'CookieInfoScript' // 為了相容舊站 Cookie Info 而取此名
    const { isShow, show, hide } = useShowable()

    onMounted(() => {
      setTimeout(() => {
        if (!Cookies.get(key)) show()
      }, 1000)
    })

    const close = () => {
      hide()
      Cookies.set(key, '1', { expires: 365 })
    }

    return {
      isShow,
      close,
    }
  },
})
