/* eslint-disable camelcase */

/** td_event_service_location */
export const tdCarModelLocation = (car_location: string) => {
  window.dataLayer.push({ td_fields: null })
  window.dataLayer.push({
    event: 'td_cusevent',
    td_event: 'td_car_model_location',
    td_fields: {
      target_location: document.getElementsByTagName('h1').item(0)?.innerText,
      car_location,
    },
  })
}

/** td_footer_company */
export const tdFooterCompany = (target_location: string, target_detail: string) => {
  window.dataLayer.push({ td_fields: null })
  window.dataLayer.push({
    event: 'td_cusevent',
    td_event: 'td_footer_company',
    td_fields: {
      target_location,
      target_detail,
    },
  })
}

/** td_footer_supplier */
export const tdFooterSupplier = (target_location: string, target_detail: string) => {
  window.dataLayer.push({ td_fields: null })
  window.dataLayer.push({
    event: 'td_cusevent',
    td_event: 'td_footer_supplier',
    td_fields: {
      target_location,
      target_detail,
    },
  })
}

/** td_footer_customer */
export const tdFooterCustomer = (target_location: string, target_detail: string) => {
  window.dataLayer.push({ td_fields: null })
  window.dataLayer.push({
    event: 'td_cusevent',
    td_event: 'td_footer_customer',
    td_fields: {
      target_location,
      target_detail,
    },
  })
}

/** td_footer_media */
export const tdFooterMedia = (target_location: string, target_detail: string) => {
  window.dataLayer.push({ td_fields: null })
  window.dataLayer.push({
    event: 'td_cusevent',
    td_event: 'td_footer_media',
    td_fields: {
      target_location,
      target_detail,
    },
  })
}

/** td_footer_service */
export const tdFooterService = (target_location: string, target_detail: string) => {
  window.dataLayer.push({ td_fields: null })
  window.dataLayer.push({
    event: 'td_cusevent',
    td_event: 'td_footer_service',
    td_fields: {
      target_location,
      target_detail,
    },
  })
}

/** td_footer_follow_us */
export const tdFooterFollowUs = (target_location: string, target_detail: string) => {
  window.dataLayer.push({ td_fields: null })
  window.dataLayer.push({
    event: 'td_cusevent',
    td_event: 'td_footer_follow_us',
    td_fields: {
      target_location,
      target_detail,
    },
  })
}

/** td_car_filter_pc */
export const tdCarFilterPc = (value: {
  brand?: string,
  age?: string,
  license?: string,
  payment?: string,
  model?: string,
  price_min?: string,
  price_max?: string,
  optional?: string,
  equipment?: string,
  sort?: string,
}) => {
  window.dataLayer.push({ td_fields: null })
  window.dataLayer.push({
    event: 'td_cusevent',
    td_event: 'td_car_filter_pc',
    td_fields: value,
  })
}

/** td_car_filter_mb */
export const tdCarFilterMb = (value: {
  sort?: string,
  brand?: string,
  age?: string,
  license?: string,
  payment?: string,
  price_min?: string,
  price_max?: string,
  model?: string,
  optional?: string,
  equipment?: string,
}) => {
  window.dataLayer.push({ td_fields: null })
  window.dataLayer.push({
    event: 'td_cusevent',
    td_event: 'td_car_filter_mb',
    td_fields: value,
  })
}

/**
 * td_car_store_slide
 * @param right 是否為向右滑動
 * @param model 品牌 車型 e.x. TOYOTA VIOS
 */
export const tdCarStoreSlide = (right: boolean, model: string) => {
  window.dataLayer.push({ td_fields: null })
  window.dataLayer.push({
    event: 'td_cusevent',
    td_event: 'td_car_store_slide',
    td_fields: {
      slide: right ? '向右' : '向左',
      model,
    },
  })
}

/** td_car_store_search */
export const tdCarStoreSearch = (value: {
  edit?: string,
  pick_date?: string,
  return_date?: string,
  number?: string,
  brand?: string,
  model?: string,
  insurance?: string,
  gear?: string,
}, isMobile?: boolean) => {
  window.dataLayer.push({ td_fields: null })
  window.dataLayer.push({
    event: 'td_cusevent',
    td_event: 'td_car_store_search',
    td_fields: {
      ...value,
      ...(isMobile && {
        edit: '修改',
      }),
    },
  })
}

/**
 * td_car_store_review
 * @param vender 車行名稱 ex:殷煌國際超跑租賃
 */
export const tdCarStoreReview = (vender: string) => {
  window.dataLayer.push({ td_fields: null })
  window.dataLayer.push({
    event: 'td_cusevent',
    td_event: 'td_car_store_review',
    td_fields: {
      vender,
    },
  })
}

/**
 * td_store_recruit
 * @param vendor 租車公司名稱 ex:殷煌國際超跑租賃
 * @param name 聯絡姓名 ex:陳大明
 */
export const tdStoreRecruit = (vendor: string, name: string) => {
  window.dataLayer.push({ td_fields: null })
  window.dataLayer.push({
    event: 'td_cusevent',
    td_event: 'td_store_recruit',
    td_fields: {
      vendor,
      name,
    },
  })
}
