



















import { defineComponent } from '@nuxtjs/composition-api'
import useFreezeBodyScroll from '@/composables/use-freeze-body-scroll'
import useI18n from '~/composables/use-i18n'
import useNuxtImage from '~/composables/use-nuxt-image'

export default defineComponent({
  props: {
    type: {
      type: String,
      default: '',
    },
  },
  setup (props) {
    const i18n = useI18n()

    useFreezeBodyScroll().setup()

    const { props: imageProps } = useNuxtImage({
      placeholder: true,
      width: 72,
      height: 40,
      imgAttrs: {
        alt: props.type === 'pay'
          ? i18n.t('loading.pay.title')
          : i18n.t('loading.search.title'),
      },
      quality: 100,
    })
    const loadingImg = 'car-loading.gif'

    return {
      loadingImg,
      imageProps,
    }
  },
})
